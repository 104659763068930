import { parseCookies, setCookie } from 'nookies';
import { GetServerSidePropsContext } from 'next';

class Cookies {
	public static nookiesSingleton: ReturnType<typeof parseCookies>;

	// For setting the cookies everywhere in the app on server
	private nextContext: GetServerSidePropsContext | null;

	constructor() {
		// For client side cookies
		Cookies.nookiesSingleton = parseCookies();

		this.nextContext = null;
	}

	public reInitWithContext(ctx: GetServerSidePropsContext) {
		this.nextContext = ctx;
		// For server side cookies
		Cookies.nookiesSingleton = parseCookies(ctx);
	}

	setCookie(...args: Parameters<typeof setCookie>): ReturnType<typeof setCookie> {
		if (this.nextContext) {
			// Append the next context to be able to set cookies everywhere in the app
			args[0] = this.nextContext;
		}

		// Save the new added cookie to the single object
		Cookies.nookiesSingleton = {
			...Cookies.nookiesSingleton,
			[args[1]]: args[2]
		};

		return setCookie.apply(this, args);
	}

	public getCookie(cookieName: string): string {
		if (!Cookies.nookiesSingleton) {
			return '';
		}

		return Cookies.nookiesSingleton[cookieName];
	}
}

const cookie = new Cookies(); // Only one single instance
export default cookie;


