import React from 'react';
import { i18n } from 'next-i18next';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import styles from './dialog.module.scss';
import { Iframe } from '../iframe/iframe';
import { environment } from '@environment';
import { PaymentForm } from '../payment-form/payment-form';

interface DialogProps {
	data: any;
	onButtonClick?: any;
	isIframe?: boolean;
}

// TODO: fix dialogs across project to be dynamic and call this component
export const DialogComponent = ({ data, onButtonClick, isIframe = false }: DialogProps) => {
	const { language } = i18n;
	const Transition = React.forwardRef(
		(
			props: TransitionProps & {
				children: React.ReactElement<any, any>;
			},
			ref: React.Ref<unknown>
		) => {
			return (
				<Slide
					ref={ref}
					direction={data.displaymode === 'toast' ? (language === 'ar' ? 'right' : 'left') : 'up'}
					{...props}
				/>
			);
		}
	);

	return isIframe ? (
		<Dialog
			fullScreen
			open
			TransitionComponent={Transition}
			className={styles['iframe-wrapper-full']}
		>
			<Iframe
				iframeSrc={data.url}
				onCloseIframe={(e) => {
					onButtonClick({
						type: 'close_iframe',
						data: e
					});
				}}
			/>
		</Dialog>
	) : (
		<Dialog
			open
			BackdropProps={{
				classes: {
					root: data.displaymode === 'toast' ? 'no-backdrop' : ''
				}
			}}
			PaperProps={{
				classes: {
					root:
						data.displaymode === 'toast'
							? styles['side-dialog']
							: data.mainClass
								? data.mainClass
								: ''
				}
			}}
			TransitionComponent={Transition}
			id="toast-dialog-id"
			className={data.displaymode === 'toast' ? 'toast-dialog' : ''}
		>
			{data.displaymode === 'custom' && (
				<div className={`${styles['dialog-wrapper']} flexbox colls black`}>
					{data.head && Object.keys(data.head).length > 0 && (
						<div
							className={`${styles['dialog-head']} ${data.warning ? styles.warning : ''}`}
							style={data.head.styles ? data.head.styles : null}
						>
							{data.warning && (
								<img src={environment.web_cdn_url + 'web/assets/img/manageaccount/warning.svg'} />
							)}
							<span style={{ padding: '0 0.5em' }}>{data.head.text}</span>
						</div>
					)}
					<div className="flexbox colls">
						<div
							className={styles['dialog-body']}
							style={data.body.styles ? data.body.styles : null}
						>
							{data.body.text}
						</div>
						{data.body.assets &&
							data.body.assets.length > 0 &&
							data.body.assets.map((elt) => {
								return <img key={`id-${elt.src}`} style={elt.styles} src={elt.src} />;
							})}
					</div>
					{data.buttons && data.buttons.length > 0 && (
						<div className="flexbox">
							{data.buttons.map((elt) => {
								return (
									<button
										key={`id-${elt.type}`}
										type="button"
										id={`id-${elt.type}`}
										style={elt.styles}
										className="default-btn"
										onClick={() => {
											onButtonClick({
												...elt,
												data: data?.data
											});
										}}
									>
										{elt.label}
									</button>
								);
							})}
						</div>
					)}
					{data.links && data.links.length > 0 && (
						<div className="flexbox">
							{data.links.map((elt) => {
								return (
									<div
										key={`id-${elt.type}`}
										className={styles.link}
										onClick={() => {
											onButtonClick(elt);
										}}
									>
										{elt.text}
									</div>
								);
							})}
						</div>
					)}
				</div>
			)}
			{data.displaymode === 'toast' && (
				<div className={styles['toast-message']}>{data.body.text}</div>
			)}
			{data.displaymode === 'cc-info' && (
				<div className={`${styles['cc-info']} flexbox colls start`}>
					<div className={`flexbox end w100 ${styles['title-wrapper-web']}`}>
						<img
							src={`${environment.new_web_cdn_url}/web/assets/img/close-icon.png`}
							className={styles['close-icon']}
							onClick={() => {
								onButtonClick({ type: 'close_dialog', isrequired: data.ispaymentrequired });
							}}
						/>
					</div>
					<div className={`flexbox ${styles['dialog-payment-wrapper']}`}>
						<div
							className={`${styles['box-border-wrapper']} ${styles['payment-cc-wrapper']}`}
							style={{ paddingBottom: '1em' }}
						>
							<div className={`${styles['payment-title']} black bold`}>{data.title}</div>
							<div className={styles['payment-subtitle']}>{data.subtitle}</div>
							<PaymentForm
								type={data.info.type}
								data={data.info.payment}
								onSubmit={(e) => {
									onButtonClick(e);
								}}
								onSuccess={(e) => {
									onButtonClick(e);
								}}
							/>
						</div>
						{data?.info?.payment?.title && (
							<div
								className={`flexbox colls w100 ${styles['payment-details-wrapper']} ${styles['box-border-wrapper']}`}
							>
								<div className={`flexbox corners w100 ${styles['plan-details']}`}>
									<div className={`${styles['payment-details-title']} black bold`}>
										{data.info.payment.title}
									</div>
								</div>
								<hr className={styles['hr-tag']} />
								<div className={`flexbox w100 corners ${styles['plan-details']} start`}>
									<div className={styles['benefit-details']}>{data.info.payment.benefit}</div>
									<div className="flexbox colls">
										<div className={styles.multiplier}>
											{data.info.payment.currency} {data.info.payment.multiplier}
										</div>
										<div>
											{data.info.payment.currency} {data.info.payment.price}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</Dialog>
	);
};

export default DialogComponent;
