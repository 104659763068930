import { isCheckoutCard } from './payment-helper';

interface Mask {
	maskArray: number[];
	regex: RegExp;
	cardtype: string;
	icon: string;
}

class Validator {
	public mask: Mask[];

	constructor() {
		this.mask = [
			{
				// mask: '0000 0000 0000 0000',
				// mask: new RegExp(/(\d{4})(\d{4})(\d{4})(\d{4})/),
				// maskLength: 4,
				maskArray: [4, 4, 4, 4],
				regex: new RegExp('^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}'),
				cardtype: 'mastercard',
				icon: '/static/cards/mastercard.svg'
			}, {
				// mask: '0000 0000 0000 0000',
				// mask: new RegExp(/(\d{4})(\d{4})(\d{4})(\d{4})/),
				// maskLength: 4,
				maskArray: [4, 4, 4, 4],
				regex: new RegExp('^(?:2131|1800|35[0-9]{3})[0-9]{11}$'),
				cardtype: 'jcb',
				icon: '/static/cards/jcb.svg'
			}, {
				// mask: '0000 0000 0000 0000',
				// mask: new RegExp(/(\d{4})(\d{4})(\d{4})(\d{4})/),
				// maskLength: 4,
				maskArray: [4, 4, 4, 4],
				regex: new RegExp('^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}'),
				cardtype: 'maestro',
				icon: '/static/cards/maestro.svg'
			}, {
				// mask: '0000 0000 0000 0000',
				// mask: new RegExp(/(\d{4})(\d{4})(\d{4})(\d{4})/),
				// maskLength: 4,
				maskArray: [4, 4, 4, 4],
				regex: new RegExp('^4[0-9]{12}(?:[0-9]{3})?$'),
				cardtype: 'visa',
				icon: '/static/cards/visa.svg'
			}, {
				// mask: new RegExp(/(\d{4})(\d{4})(\d{4})(\d{4})/),
				// maskLength: 4,
				maskArray: [4, 4, 4, 4],
				regex: new RegExp('^(\d{4}[- ]){3}\d{4}|\d{16}$'),
				cardtype: 'Unknown',
				icon: ''
			}
		];
	}

	validateCardNumber = (cardNum: string) => {
		const value = this.mask.find(mask => {
			return mask.regex.test(cardNum.replace(/\s/g, ''));
		});
		const isCheckout = isCheckoutCard({
			ccnumber: cardNum
		});
		if (isCheckout) {
			return {
				maskArray: [4, 4, 4, 4],
				cardtype: 'mada',
				icon: '/static/cards/mada.svg'
			};
		}

		return value;
	};

	formatCardNumber(cardNum: string, mask = [4, 4, 4, 4]) {
		const num = cardNum.replace(/\s/g, '');
		let counter = 0;
		let divider = mask[0];
		let newNumber = '';
		for (const char of mask) {
			if (divider < num.length) {
				newNumber = newNumber + ' ' + num.substring(counter, divider);
				counter = divider;
				divider += char;
			} else {
				newNumber = newNumber + ' ' + num.substring(counter, num.length);
				break;
			}
		}

		return newNumber.trim();
	}
}

const ccValidator = new Validator();

export default ccValidator;
