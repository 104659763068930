import { checkoutCards } from '@enums/utils-constants';
import { environment } from '@environment';
import { Card } from '@interfaces/data.interface';
import reportAmplitudeEvent, { sendRaygunErrorMessage } from '@helpers/analytics';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import postPromocode from '@anghami/neogateway/dist/endpoints/postPromocode';
import postUpdatePurchaseStatus from '@anghami/neogateway/dist/endpoints/postUpdatePurchaseStatus';
import { filterParams } from '@helpers/operators-helper';
import { updateCCStatus } from '@enums/manage-account-constants';
import { gateway } from './neogateway';

export const isCheckoutCard = (card) => {
	return getTypedCard(card, checkoutCards);
};

const AMPLITUDE_EVENTS = {
	UPGRADE_PLAN_STATUS: 'manage account upgrade',
	UPDATE_CC_SUCCESS: 'manage account - success update card info'
};

const getTypedCard = (card, typeLst) => {
	if (card && card !== null) {
		const ccnumber = card.ccnumber ? card.ccnumber.replace(/\s/g, '') : '';
		for (const card of typeLst) {
			if (ccnumber.indexOf(card) !== -1 && ccnumber.startsWith(card)) {
				return true;
			}
		}
	}

	return false;
};

/**
 * @description checks whether we should apply card tokenization to proceed with payment or not
 */
const shouldApplyCheckout = (card, cardPayment) => {
	return Number(cardPayment) === 53; // removed the isCheckoutCard(card) condition based on a request from billing team
};

/**
 * @description fetches checkout public key and merchant id to be used when getting
 * the token for the card based on the country provided by API
 */
const getCheckoutCredentials = (country?) => {
	const merchantInfo = environment.merchant_info;
	let credentials: any = {
		public_key: merchantInfo.default.checkout_public_key,
		merchant_id: merchantInfo.default.merchant_id
	};
	if (country && country !== null && merchantInfo[country]) {
		credentials = {
			public_key: merchantInfo[country].checkout_public_key,
			merchant_id: merchantInfo[country].merchant_id
		};
	}

	return credentials;
};

const checkoutScript = async (card: Card) => {
	const checkoutCard = {
		country: card.country,
		cvv: card.cvv,
		number: card.ccnumber,
		expiryMonth: card.ccmonth,
		expiryYear: card.ccyear,
		name: card.card_holder_name,
		requestSource: 'JS'
	};
	return postCheckoutScript(checkoutCard);
};

const postCheckoutScript = async (params) => {
	const country =
		params.country !== undefined && params.country !== null ? params.country : 'default';
	const publicKey = getCheckoutCredentials(country).public_key;
	const checkoutUrl =
		country === 'XX' ? environment.checkout_sandbox_url : environment.checkout_url;
	const options: any = {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: {
			'Content-Type': 'application/json',
			Authorization: publicKey
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
		body: JSON.stringify(params) // body data type must match "Content-Type" header
	};
	return fetch(checkoutUrl, options);
};

const fetchCheckoutCard = async (card) => {
	try {
		return await checkoutScript(card);
	} catch (_err) {
		sendRaygunErrorMessage(new Error('CheckoutScript error'), JSON.stringify(_err));
		return { data: null };
	}
};

const handleCheckoutPayment = async (params) => {
	reportAmplitudeEvent(AmplitudeEvents.runCheckoutScript, {});
	return new Promise((resolve) => {
		fetchCheckoutCard(params)
		.then((response: any) => {
			if (response) {
				return response.json().then((data) => ({
					data,
					status: response.status
				}));
			}

			return { data: null };
		})
		.then((res: any) => {
			const { data } = res;
			reportAmplitudeEvent(AmplitudeEvents.handlingCheckoutResponse, {});

			if (data && Object.keys(data).length > 0) {
				if (data.errors) {
					resolve({
						error: data
					});
				} else {
					const cardParams: any = {
						token: data.id,
						isCheckout: 1
					};
					Object.assign(cardParams, {
						bin: data.card?.bin,
						expiryMonth: data.card?.expiryMonth,
						expiryYear: data.card?.expiryYear,
						last4: data.card?.last4,
						country: params?.country,
						cardholder_name: data.card?.name
					});
					resolve({
						card: cardParams
					});
				}
			} else if (res?.status !== 200) {
				resolve({
					error: {
						message: ''
					}
				});
			}
		});
	});
};

export const fetchCardData = async (params, planId, cardPayment?) => {
	if (shouldApplyCheckout(params, cardPayment)) {
		return handleCheckoutPayment(params);
	}

	return new Promise((resolve) => {
		const cardParams = {
			...params,
			cardholder_name: params?.card_holder_name
		};
		delete cardParams?.card_holder_name;
		resolve({ card: cardParams });
	});
};

export const submitPayment = async (data, type) => {
	const cardResponse: any = await fetchCardData(data.card, data.planid, data.cardPayment);
	if (cardResponse) {
		if (cardResponse.card) {
			const card = {
				...cardResponse.card,
				appsid: data.userappsid,
				...setExtraCCParams(data)
			};
			filterParams(card);
			const paymentres = await paymentAction(card, type);
			return handlePaymentResponse(paymentres.data || paymentres);
		}

		if (cardResponse.error) {
			return new Promise((resolve) => {
				resolve(cardResponse);
			});
		}
	}
};

export const setExtraCCParams = (data) => {
	return {
		webmethod: 'credit card',
		operatorid: 0,
		price: 0,
		amount: 0,
		os: 'web',
		coupon: 0,
		output: 'jsonhp',
		planid: data.planid,
		promocode: data.promocode,
		merchant_id: data.country
	};
};

export const handlePaymentResponse = async (result, type?) => {
	if (result) {
		let res: any = {};
		if (result.error) {
			const error = buildPaymentError(result);
			if (error.iframeurl) {
				res = {
					result: iframeHandling(error),
					error
				};
			} else {
				res = { error };
			}
		} else {
			res = result;
		}

		return new Promise((resolve) => {
			resolve(res);
		});
	}

	// TODO: handle success
	// TYPE #1: we should show a dialog confirming that card got updated or saved new card info
	// TYPE #2: success payment which might not be needed at the moment
	switch (type) {
		case 'update': // TYPE #1
			break;
		default: // TYPE #2, default payment
			break;
	}
};

const iframeHandling = (data) => {
	switch (data.iframetype) {
		case 'tab':
			break;
		case 'iframe':
			return data.iframeurl;
		default:
			break;
	}
};

export const handleIframeClose = (result, planid?) => {
	const params = result?.params ? result.params : null;
	let type = '';
	if (params !== null) {
		const ampEventObject: any = {
			name: AMPLITUDE_EVENTS.UPGRADE_PLAN_STATUS,
			props: {
				status: 'success'
			}
		};
		if (result?.actionType) {
			type = `${result.actionType}${
				Number(params.success) === 1 ? '_success' : Number(params.fail) === 1 ? '_failure' : ''
			}`;
		}

		if (Number(params.isupdate) === 1 && Number(params.success) === 1) {
			ampEventObject.name = AMPLITUDE_EVENTS.UPDATE_CC_SUCCESS;
			type = updateCCStatus.success;
		}

		if (Number(params.fail) === 1 && params['']) {
			ampEventObject.name = AMPLITUDE_EVENTS.UPGRADE_PLAN_STATUS;
			ampEventObject.props = {
				...ampEventObject.props,
				status: 'failure',
				message: params.message
			};
		}

		if (planid && planid !== null) {
			ampEventObject.props = {
				...ampEventObject.props,
				planid
			};
		}

		reportAmplitudeEvent(ampEventObject.name, ampEventObject.props);
		return type;
	}
};

const paymentAction = async (card, type) => {
	switch (type) {
		case 'redeem_promocode':
			return gateway.callEndpoint(postPromocode, {
				...card,
				confirm: 1
			});
		default:
			break;
	}
};

export const buildPaymentError = (result) => {
	const error = {
		...(result.error._attributes || result.error),
		iframeurl: result.openpopup || result.openiframe,
		iframetype: result.openpopup ? 'tab' : 'iframe'
	};
	return error;
};

export const onSuccessRedirect = (data) => {
	if (data && Object.keys(data).length > 0) {
		const encodeExtraData = btoa(encodeURIComponent(JSON.stringify(data)));
		const url = new URL('https://anghami.com/subscriptioncheck');
		if (data.success) {
			url.searchParams.append('success', data.success);
		}

		url.searchParams.append('extraData', encodeExtraData);
		const finalURL = url.toString();
		window.location.href = finalURL;
	}
};

export const cancelTamaraOffer = async (params) => {
	return gateway.callEndpoint(postUpdatePurchaseStatus, params);
};
