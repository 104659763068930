import { environment } from '@environment';
import { ImageLoaderProps } from 'next/image';
import cookie from './cookies';
import { defaultProfilePicture } from '@enums/user';

export const getSidFromUrl = () => {
	if (typeof window !== 'undefined') {
		const { search } = window.location;
		const params = new URLSearchParams(search);
		return params.get('sid') || params.get('appsid');
	}

	return '';
};

const getLoggedIn = (sssFromCookie) => sssFromCookie || Boolean(getSidFromUrl());

export const getSeperateTextAndNumber = (text) => {
	if (text && text.length > 0) {
		// (\b(([0-9]+[,]*[0-9]+[0-9]+))\b)
		let num;
		const splitOnNumber = text?.replace(new RegExp('([0-9]+[,]?[0-9]+|[0-9]+)', 'g'), (e) => {
			num = e;
			return '..';
		});
		const splitObject = splitOnNumber?.split('..');
		const numberOnly = num;
		return {
			first_part: splitObject[0] ? splitObject[0].trim() : null,
			second_part: splitObject[1] ? splitObject[1].trim() : null,
			number: numberOnly
		};
	}

	return {
		first_part: null,
		second_part: null,
		number: null
	};
};

export const getSeperateText = (
	text,
	highlitedText
): { first_part: string; second_part: string; highlited: string } => {
	if (text && highlitedText) {
		const splitOnNumber = text?.replace(highlitedText, '..');
		const splitObject = splitOnNumber.split('..');
		return {
			first_part: splitObject[0] ? splitObject[0].trim() : null,
			second_part: splitObject[1] ? splitObject[1].trim() : null,
			highlited: highlitedText
		};
	}

	return {
		first_part: null,
		second_part: null,
		highlited: null
	};
};

export const isTextLong = (text, length) => {
	return text.length > length;
};

export const shortenText = (text, length) => {
	if (text.length > length + 3) {
		return text.slice(0, length) + '...';
	}

	return text;
};

export const getParsedCookie = (cookie: string) =>
	cookie?.length > 0
		? cookie.split(';').reduce((cookieObject, cookieString) => {
			const splitCookie = cookieString.split('=');
			try {
				cookieObject[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1]);
				/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
			} catch (err) {
				cookieObject[splitCookie[0].trim()] = splitCookie[1];
			}

			return cookieObject;
			/* eslint-disable-next-line  no-mixed-spaces-and-tabs */
		  }, [])
		: null;

export const isMobile = (): boolean => {
	if (typeof window === 'undefined' || typeof navigator === 'undefined') {
		return false;
	}

	if (navigator?.userAgent) {
		if (
			/Android/i.exec(navigator.userAgent) ||
			/webOS/i.exec(navigator.userAgent) ||
			/iPhone/i.exec(navigator.userAgent) ||
			/iPad/i.exec(navigator.userAgent) ||
			/iPod/i.exec(navigator.userAgent) ||
			/BlackBerry/i.exec(navigator.userAgent) ||
			/Windows Phone/i.exec(navigator.userAgent) ||
			navigator.userAgent.includes('anghami')
		) {
			return true;
		}
	}

	return false;
};

export const setCookie = (cname, cvalue, exdays) => {
	const d = new Date();
	// days to milliseconds * 24 * 60 * 60 * 1000 = * 86400000
	// eslint-disable-next-line no-mixed-operators
	d.setTime(d.getTime() + exdays * 86400000);
	const expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const isArabic = (text) => {
	const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
	const result = pattern.test(text);
	return result;
};

export const updateQueryString = (key, value, url) => {
	if (!url) {
		url = window.location.href;
	}

	const re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi');
	let hash;

	if (re.test(url)) {
		if (typeof value !== 'undefined' && value !== null) {
			return url.replace(re, '$1' + key + '=' + value + '$2$3');
		}

		hash = url.split('#');
		url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
		if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
			url += '#' + hash[1];
		}

		return url;
	}

	if (typeof value !== 'undefined' && value !== null) {
		const separator = url.indexOf('?') === -1 ? '?' : '&';
		hash = url.split('#');
		url = hash[0] + separator + key + '=' + value;
		if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
			url += '#' + hash[1];
		}

		return url;
	}

	return url;
};

export const b64DecodeUnicode = (str) => {
	// Going backwards: from bytestream, to percent-encoding, to original string.
	try {
		const validBase64 = atob(str);
		return decodeURIComponent(
			validBase64
			.split('')
			.map((c) => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
		);
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	} catch (e) {
		const decodeStr = decodeURIComponent(str);
		return decodeURIComponent(
			atob(decodeStr)
			.split('')
			.map((c) => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
		);
	}
};

export const setLanguageCookies = (lang: string, ctx = null) => {
	['userlanguageprod', 'language', 'NEXT_LOCALE'].forEach((cookieName) => {
		cookie.setCookie(ctx, cookieName, lang, {
			maxAge: 30 * 24 * 60 * 60,
			domain: '.anghami.com',
			path: '/'
		});
		cookie.setCookie(ctx, cookieName, lang, {
			maxAge: 30 * 24 * 60 * 60,
			domain: '.angha.me',
			path: '/'
		});
	});
};

export const getOS = () => {
	if (typeof window !== 'undefined') {
		if (navigator.userAgent.indexOf('iPhone') > 1) {
			return 'iphone';
		}

		if (navigator.userAgent.indexOf('Android') > 1) {
			return 'android';
		}
	}

	return 'web';
};

export const isWeb = (userAgent) => {
	const agent = userAgent || navigator?.userAgent;
	if (
		/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
			agent
		) ||
		/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
			agent.substr(0, 4)
		)
	) {
		return false;
	}

	return true;
};

export default getLoggedIn;

/**
 * @description retrieve params in url in an object
 */
export const getQueryParamsfromUrl = (url) => {
	const extraparams = {};
	if (url.split('?').length > 1) {
		let urlparams = url.split('?')[1];
		urlparams = urlparams.split('&');
		urlparams.forEach((param) => {
			const a = param.split('=');
			extraparams[a[0]] = a[1];
		});
	}

	return {
		extraParams: extraparams,
		baseUrl: url.split('?')[0]
	};
};

export const appendQueryParamstoUrl = (url, params) => {
	let updatedUrl = `${url}?`;
	Object.keys(params).forEach((key) => {
		updatedUrl += `${key}=${params[key]}&`;
	});
	return updatedUrl.substring(0, updatedUrl.length - 1);
};

export const getQueryFromUrl = (variable: string): string => {
	const query = window.location.search.substring(1);
	const vars = query.split('&');

	// eslint-disable-next-line
	for (let i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		try {
			if (decodeURIComponent(pair[0]) === variable) {
				return decodeURIComponent(pair[1]);
			}
		} catch (_) {
			// Skip URI decoding if the url is malformed, commonly happens with sid tokens
			if (pair[0] === variable) {
				return pair[1];
			}
		}
	}
};

export const parseUtmParamsFromLocation = (): {
	utm_campaign: string;
	utm_source: string;
	utm_content: string;
} => {
	const utm_campaign = getQueryFromUrl('utm_campaign');
	const utm_source = getQueryFromUrl('utm_source');
	const utm_content = getQueryFromUrl('utm_content');
	return {
		utm_campaign,
		utm_source,
		utm_content
	};
};

export const hexToRGB = (hex = '#3E887E') => {
	hex = hex.replace(/([^\u0621-\u063A\u0641-\u064A\u0660-\u0669a-zA-Z 0-9])/g, '');
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
	return result
		? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
		: null;
};

export const addAlpha = (color, opacity) => {
	// coerce values so ti is between 0 and 1.
	const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
	return color + _opacity.toString(16).toUpperCase();
};

export const handleArabicEncoding = (value) => {
	return value?.replace('\u200f', '');
};

export const getUTMTagsFromUrl = (url: string) => {
	const campaignparams = {};
	let queryString = '';
	if (url.includes('utm_campaign')) {
		// Check if this url has campaign params that need to be reported
		if (!url.includes('?') && url.includes('#')) {
			// Extra check for weird query string start sign ('#' instead of '?')
			const newUrl = url.split('#');
			const qs = newUrl[1];
			queryString = decodeURIComponent(qs);
		}

		if (url.includes('?')) {
			queryString = decodeURIComponent(location.search);
			queryString = queryString.substring(1);
		}

		if (!queryString) {
			return {};
		}

		const pairs = queryString.split('&');
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < pairs.length; i++) {
			const keyValuePair = pairs[i].split('=');
			const key = keyValuePair[0];
			const val = keyValuePair[1];
			campaignparams[key] = val;
		}

		return campaignparams;
	}

	return {};
};

export const fetchRetry = async (url, options, n) => {
	try {
		return await fetch(url, options);
	} catch (err) {
		if (n === 1) {
			throw err;
		}

		return fetchRetry(url, options, n - 1);
	}
};

export const isServer = () => {
	return typeof window === 'undefined';
};

export const isBrowser = () => {
	return typeof window !== 'undefined';
};

export const getCoverArtUrl = ({ src, width }: ImageLoaderProps) => {
	if (src.startsWith('http')) {
		return src;
	}

	return `${environment.artwork_cdn_url}?id=${src}&size=${width}`;
};

export const siFormat = (num?: number) => {
	if (!num) {
		return '';
	}

	const si = ['', 'K', 'M', 'B'];
	const tier = (Math.log10(num) / 3) | 0;

	if (tier === 0) {
		return `${num}`;
	}

	const scaleSpecifier = si[tier];
	const scale = 10 ** (tier * 3);
	const scaled = num / scale;

	return scaled.toFixed(1) + scaleSpecifier;
};

export const encodeObjectToBase64 = (data) => {
	const _jsonString = JSON.stringify(data);

	// Convert the JSON string to a Uint8Array
	const _uint8Array = new TextEncoder().encode(_jsonString);

	// Convert the Uint8Array to a base64 string
	return btoa(String.fromCharCode.apply(null, _uint8Array));
};

export const decodeBase64String = (value) => {
	// Convert the base64 string to a Uint8Array
	const _decodedUint8Array = new Uint8Array(
		atob(value)
		.split('')
		.map((char) => char.charCodeAt(0))
	);

	// Convert the Uint8Array to a JSON string
	const _decodedJsonString = new TextDecoder().decode(_decodedUint8Array);

	// Parse the JSON string to get the data object
	return JSON.parse(_decodedJsonString);
};

export const handleProfilePictureError = ({ currentTarget }) => {
	currentTarget.onerror = null;
	currentTarget.src = defaultProfilePicture;
};
