import React from 'react';
import PropTypes from 'prop-types';

interface ErrorProps {
	className: string;
	language: string;
	message: string;
}

export const ErrorNotice = ({ className, language, message }: ErrorProps) => {
	return (
		<div
			className={className}
			style={{
				backgroundColor: 'rgba(222, 27, 27, 0.1)',
				color: '#DE1B1B',
				borderRadius: '10px',
				border: '1px solid #DE1B1B',
				padding: '0.7em 1em',
				marginBottom: '1.5em',
				flexDirection: 'column',
				alignItems: 'flex-start',
				direction: language === 'ar' ? 'rtl' : 'ltr',
				textAlign: language === 'ar' ? 'right' : 'left',
				width: 'fit-content'
			}}
		>
			<span id="plan-title" style={{ fontWeight: 500, display: 'block' }}>{message}</span>
		</div>
	);
};

ErrorNotice.propTypes = {
	className: PropTypes.string.isRequired,
	language: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

export default ErrorNotice;
