export interface IDeeplink {
	name: string;
	routeParam?: string;
	queryStrings: {
		fullString: string;
		individualStrings: any;
	};
}

import { isMobile } from './helper';

export const handleDeeplink = (deeplinkUrl: string, isBlank?) => {
	if (deeplinkUrl.includes('https://') && !deeplinkUrl.includes('anghami://')) {
		window.open(deeplinkUrl, '_blank');
		return;
	}

	const deeplinkObject: IDeeplink = getParsedDeeplinkObject(deeplinkUrl);
	let fullUrl: string;
	// remove unicode characters from name, string comparison was failing (when in ar)
	const deepLinkName = String(deeplinkObject.name)
	.replace(/[^\x00-\x7F]/g, '')
	.trim();

	switch (deepLinkName) {
		case 'refresh':
			location.reload();
			break;
		case 'deeplink-settings':
			// Deeplinks back into the app - used in mobile webviews
			window.location.href = 'anghami://settings';
			break;
		case 'purchase':
			window.open(`https://www.anghami.com/plus/${deeplinkObject.routeParam}`, '_blank');
			break;
		case 'upgrade':
			window.open(
				`https://www.anghami.com/plus?${deeplinkObject.queryStrings.fullString}`,
				'_blank'
			);
			break;
		case 'plus':
			window.open('https://www.anghami.com/plus', '_blank');
			break;
		case 'webl:':
			fullUrl = 'https://' + deeplinkUrl.replace('webl://', '');
			openLink(fullUrl, isBlank);
			break;
		case 'webs:':
			fullUrl = 'https://' + deeplinkUrl.replace('webs://', '');
			openLink(fullUrl, isBlank);
			break;
		case 'webl':
			fullUrl = deeplinkUrl.replace('anghami://webl/', 'http://');
			openLink(fullUrl, isBlank);
			break;
		case 'webs':
			fullUrl = deeplinkUrl.replace('anghami://webs/', 'https://');
			openLink(fullUrl, isBlank);
			break;
		case 'browser':
			fullUrl = deeplinkUrl.replace('anghami://browser/', 'https://');
			openLink(fullUrl, isBlank);
			break;
		case 'browser:':
			fullUrl = deeplinkUrl.replace('browser', 'https');
			openLink(fullUrl, isBlank);
			break;
		case 'sendgift':
			fullUrl = deeplinkUrl.replace('anghami://', 'https://play.anghami.com/');
			openLink(fullUrl, isBlank);
			break;
		case 'live':
			fullUrl = isMobile() ? deeplinkUrl : 'https://play.anghami.com/liveradios';
			openLink(fullUrl, false);
			break;
		default:
			// Generic/:name, song/:id, album/:id etc fall here
			openLink(deeplinkUrl, false);
			break;
	}
};

const getParsedDeeplinkObject = (deeplinkUrl: string): IDeeplink => {
	const deeplinkObject: IDeeplink = {
		name: '',
		routeParam: '',
		queryStrings: {
			fullString: '',
			individualStrings: {}
		}
	};

	const deeplinkContents = deeplinkUrl.replace('anghami://', '');
	const nameAndArgsSplit = deeplinkContents.split('?');
	const deeplinkName = nameAndArgsSplit[0];
	const nameSplit = deeplinkName.split('/');
	const args = nameAndArgsSplit[1];
	deeplinkObject.name = nameSplit[0];

	if (nameSplit.length > 1) {
		// Check for routeParam
		deeplinkObject.routeParam = nameSplit[nameSplit.length - 1];
	}

	const deeplinkQueryStrings = args?.split('&') || [];
	deeplinkObject.queryStrings.fullString = args;
	deeplinkQueryStrings.forEach((qs) => {
		// Manual split is done here because the 2nd part of the string may contain the '=' char
		// when it is a base64 encoded string.
		const indexToSplitAt = qs.indexOf('=');
		const key = qs.substring(0, indexToSplitAt);
		const value = qs.substring(indexToSplitAt + 1, qs.length);
		deeplinkObject.queryStrings.individualStrings[key] = value;
	});

	return deeplinkObject;
};

export const openLink = (url: string, isBlank) => {
	if (isBlank || Boolean((window as any).desktopClient)) {
		window.open(url);
	} else {
		window.location.replace(url);
	}
};
