import { environment } from './env/environment';

export default {
	title: 'Anghami - The world is listening',
	description:
		'All the music you love, all the freedom you need. Arabic and International in one place',
	languageAlternate: [
		{
			hrefLang: 'en',
			href: 'https://www.anghami.com'
		},
		{
			hrefLang: 'fr',
			href: 'https://www.anghami.com?lang=fr'
		},
		{
			hrefLang: 'ar',
			href: 'https://www.anghami.com?lang=ar'
		}
	],
	openGraph: {
		type: 'website',
		locale: 'en_US',
		url: 'https://www.anghami.com',
		site_name: 'Anghami',
		images: [
			{
				url: environment.new_web_cdn_url + '/web/assets/img/logos/NEW_METAS_BG.png',
				alt: 'Anghami - The world is listening'
			}
		]
	},
	twitter: {
		handle: '@anghami',
		site: '@anghami',
		cardType: 'summary_large_image'
	}
};
