import cookies from './cookies';

const TAPFILIATE_ACCOUNT_ID = '39438-639ab4';
const COOKIE_NAME = 'click_id';

const initTapfiliateScript = async () => {
	return new Promise((resolve) => {
		// We use this global script to be able to load the libraries asynchronously
		// Create the first script element
		const script1 = document.createElement('script');
		script1.src = 'https://script.tapfiliate.com/tapfiliate.js';
		script1.type = 'text/javascript';
		script1.async = true;

		// Create the second script element
		const script2 = document.createElement('script');
		script2.type = 'text/javascript';
		script2.innerHTML =
			'(function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){(t[a].q=t[a].q||[]).push(arguments)}})(window,\'tap\');';

		// Append the script elements to the head of the document
		document.head.appendChild(script1);
		document.head.appendChild(script2);
		resolve(true);
	});
};

export const generateTapfiliateClick = async (ref: string) => {
	await initTapfiliateScript();
	const { tap } = window as any;
	if (tap) {
		tap('create', TAPFILIATE_ACCOUNT_ID, { integration: 'javascript' });
		tap('click', { referral_code: ref }, { always_callback: true }, (error: any, data: any) => {
			if (!data?.id) {
				return;
			}

			cookies.setCookie(null, COOKIE_NAME, data.id, {
				maxAge: 45 * 24 * 60 * 60
			});
		});
	}
};
