import postPurchaseTelco from '@anghami/neogateway/dist/endpoints/postPurchaseTelco';
import subscribePreorder from '@anghami/neogateway/dist/endpoints/subscribePreorder';
import getPreorder from '@anghami/neogateway/dist/endpoints/getPreorder';
import getTelcoConcert from '@anghami/neogateway/dist/endpoints/getTelcoConcert';
import { gateway } from './neogateway';
import { handleArabicEncoding } from './helper';
import reportAmplitudeEvent from './analytics';
import { AmplitudeEvents } from '@enums/amplitude';
import getRbtInfo from '@anghami/neogateway/dist/endpoints/getRbtInfo';
import getOperatorPlan from '@anghami/neogateway/dist/endpoints/getOperatorPlan';
import { environment } from '@environment';

// Types of operators available
const operatorType = ['default', 'rbt', 'preorder', 'concerts'];

const baseCoverUrl = environment.artwork_cdn_url;

export const getOperatorType = (operatorname) => {
	const type = operatorType.find((elt) => elt === operatorname.toLowerCase());
	if (type) {
		return type;
	}

	return 'default';
};

export const setMsidnDetails = (plan) => {
	let validations = {};
	if (checkOperatorValidationsExist(plan)) {
		validations = {
			min_digits: plan.min_digits,
			max_digits: plan.max_digits,
			countrycode: plan.country_code
		};
	}

	return validations;
};

const checkOperatorValidationsExist = (plan) =>
	plan.min_digits && plan.max_digits && plan.country_code;

export const autofillPhoneNumber = (msidn, isAutoSub, countrycode?) => {
	let mobilenum = '';
	if (msidn) {
		const msidncode = msidn.substring(0, 3);
		if (msidncode === countrycode || isAutoSub) {
			mobilenum = msidn.replace(/^.{3}/g, '');
		}
	}

	return mobilenum;
};

export const filterParams = (params) => {
	Object.keys(params).forEach(
		(key) =>
			(params[key] === undefined ||
				params[key] === null ||
				(typeof params[key] === 'object' && Object.keys(params[key]).length === 0)) &&
			delete params[key]
	);
};

export const setOperatorDetails = (data, params) => {
	data = {
		...data,
		coverImageUrl: getCoverArtImage(data.coverartid, data.coverartsize)
	};
	return setOperatorMobileDetails(data, params);
};

// TODO: change name of function later on to something more meaningful
const setOperatorMobileDetails = (data, params) => {
	const plan = {
		...data,
		operatorname: data.operatorname ? handleArabicEncoding(data.operatorname) : undefined
	};
	const msidn =
		data.msidn && data.msidn !== null && data.msidn !== '' ? data.msidn : params.msidn || '';
	return {
		plan,
		mode: data ? data.mode : null,
		mobilenumber: autofillPhoneNumber(msidn, false, data.country_code),
		isSms: plan.api === 'sms' || data.purchase_method === 'sms',
		step: plan.api === 'sms' ? '' : 'operator',
		redirectUrl: plan.redirect_url || '',
		phoneValidations: setMsidnDetails(data) // this._subOperatorService.msidnValidations
	};
};

/*
 * @param coverArtImage cover art image data attribute
 * @param size image size, defaults to 296
 * @description returns the proper url to render the cover art image
 */
export const getCoverArtImage = (coverArtImage, size = 296) => {
	if (isNaN(Number(coverArtImage))) {
		return decodeURIComponent(coverArtImage);
	}

	return `${baseCoverUrl}?id=${coverArtImage}&size=${size}`;
};

/**
 * @description Based on operator type, fetch initial data to output (header, partner, etc.)
 */
export const getOperatorData = async (type, params) => {
	switch (type) {
		case 'preorder': {
			return gateway.callEndpoint(getPreorder, params);
		}

		case 'concerts': {
			return gateway.callEndpoint(getTelcoConcert, params);
		}

		case 'rbt': {
			return gateway.callEndpoint(getRbtInfo, params);
		}

		default:
			return gateway.callEndpoint(getOperatorPlan, params);
	}
};

const subscribeTelco = async (params: any) => {
	const data = {
		...params
	};
	delete data.type;
	return gateway.post(params.type, data, data);
};

export const subscribeOperator = async (type, params) => {
	switch (type) {
		case 'preorder': {
			return gateway.callEndpoint(subscribePreorder, params);
		}

		case 'concerts': {
			return gateway.callEndpoint(postPurchaseTelco, params);
		}

		default:
			return subscribeTelco(params);
	}
};

export const reportConversion = (amplitudeProps) => {
	reportAmplitudeEvent(AmplitudeEvents.subscriptionSuccess, {
		...amplitudeProps,
		type: 'Success'
	});
};
