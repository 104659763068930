import React from 'react';
import NextApp from 'next/app';
import { DefaultSeo } from 'next-seo';
import { appWithTranslation, i18n } from 'next-i18next';
import Head from 'next/head';
import { initAnalytics } from '@helpers/analytics';
import SEO from '../next-seo.config';
import { initNeoGatewayBrowser } from '@helpers/neogateway';
import { isServer } from '@helpers/helper';
import DialogsWrapper from '@components/dialogs-wrapper/dialogs-wrapper';
import { generateTapfiliateClick } from '@helpers/tapfiliate-helper';
import './styles.scss';
import './landing.css';

interface IAppProps {
	Component: any;
	pageProps: any;
	userProfile: any;
	SID: string;
	sss: string;
	userlanguageprod: string;
}

class App extends NextApp<IAppProps> {
	state = {
		gatewayInitialized: isServer() // Intialize by default in server environment
	};

	async componentDidMount() {
		try {
			await initNeoGatewayBrowser(this.props.pageProps.SID, this.props.pageProps.lang);
			this.setState({ gatewayInitialized: true });
			const isInnerWeb = Boolean(Number(this.props.router?.query?.innerweb));
			this.props.pageProps.isInnerWeb = isInnerWeb;
			// this will only fire in the browser
			initAnalytics(this.props.pageProps.userProfile, isInnerWeb);
			if (this.props.router?.query?.ref) {
				generateTapfiliateClick(String(this.props.router?.query?.ref));
			}
		} catch (_) {}

		i18n?.on('languageChanged', () => {
			document.location.reload();
		});
	}

	componentWillUnmount() {
		i18n.off('languageChanged');
	}

	render() {
		const { Component, pageProps } = this.props as any;
		i18n?.changeLanguage(pageProps.lang); // Force next-i18n to use the lang from querystring or cookies
		return (
			<>
				<Head>
					<meta
						name="viewport"
						content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
					/>
				</Head>
				<DefaultSeo {...SEO} />
				<main
					className={`${pageProps.isInnerWeb ? 'inner-web' : ''} ${
						pageProps.isDarkMode ? 'dark-mode' : ''
					}`}
					style={{
						background: pageProps.isInnerWeb ? (pageProps.isDarkMode ? '#1b1c1d' : '#ffffff') : '',
						minHeight: '100dvh'
					}}
				>
					{this.state.gatewayInitialized && <Component {...pageProps} />}
					{!isServer() ? <DialogsWrapper /> : null}
				</main>
			</>
		);
	}
}

export default appWithTranslation(App);
