import { environment } from '@environment';
import NeoAnalytics from '@anghami/neoanalytics';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import { GoogleAnalytics } from '@anghami/neoanalytics/dist/sdks/google-analytics';
import { Amplitude } from '@anghami/neoanalytics/dist/sdks/amplitude';
import { Raygun } from '@anghami/neoanalytics/dist/sdks/raygun';
import { isServer } from './helper';

let analytics: any;
const eventsQueue = [];

export const initAnalytics = async (user, isInnerWeb: boolean, isTurnOffLogging?: boolean) => {
	if (typeof window !== 'undefined' && !analytics) {
		analytics = new NeoAnalytics({
			project: {
				name: 'web2',
				defaultProperties: {
					web2: true,
					web_medium: 'LANDING'
				},
				config: {
					version: '0.1'
				}
			},
			amplitude: {
				key: environment.amplitudeKey,
				isTurnOffLogging
			},
			gSiteTag: {
				key: environment.gSiteTagKey
			},
			raygun: {
				key: environment.raygun
			}
		});

		await GoogleAnalytics.getGoogleAnalyticsInstance();
		await Amplitude.getAmplitudeInstance();
		await Raygun.getRaygunInstance();

		handleUserAnalyticsSetup(user);
		let userStatus = 'not logged in';
		if (user) {
			userStatus = user.plantype === '3' ? 'plus' : 'not plus';
		}

		const _urlParams: any = new URLSearchParams(window.location.search);
		const _amplitudeProps: any = {
			user_status: userStatus,
			source: isInnerWeb ? 'innerweb' : 'web'
		};

		if (_urlParams?.get('utm_source')) {
			_amplitudeProps.utm_source = _urlParams?.get('utm_source');
		}

		reportAmplitudeEvent(AmplitudeEvents.openApp, _amplitudeProps);
		eventsQueue.forEach((event) => reportAmplitudeEvent(event.eventName, event.eventProperties));
	}
};

const reportAmplitudeEvent = (eventName, eventProperties = {}) => {
	const IS_LOCAL_ENV = process.env.NODE_ENV !== 'production';

	if (IS_LOCAL_ENV) {
		Amplitude.getAmplitudeInstance().then((instance) => {
			if (instance) {
				instance.logConsoleLogs(eventName, eventProperties);
			}
		});
	} else if (typeof window !== 'undefined') {
		if (!Amplitude.amplitudeInstance) {
			return eventsQueue.push({
				eventName,
				eventProperties
			});
		}

		Amplitude.getAmplitudeInstance().then((instance) => {
			instance.logAmplitudeEvent(eventName, eventProperties);
		});
	}
};

export const sendRaygunErrorMessage = (error, customData) => {
	// const tags = ['Custom Error'];
	// analytics.raygunInstance.sendRaygunErrorMessage(error, tags, customData);
	(window as any).rg4js('send', {
		error: error instanceof Error ? error : new Error(error),
		tag: ['Custom landing error'],
		customData: customData || {}
	});
};

const handleUserAnalyticsSetup = async (user) => {
	if (user?.id) {
		const newuser = {
			...user,
			anid: user.id
		};
		NeoAnalytics.setUserData(newuser);
	}
};

/**
 * @returns {string} the device id added by amplitude
 */
export const getAmplitudeDeviceId = (): string => {
	if (isServer()) {
		return '';
	}

	const { amplitude } = window as any;
	if (!amplitude) {
		return '';
	}

	const amplitudeInstance = amplitude.getInstance();
	return amplitudeInstance?.options?.deviceId || '';
};

export default reportAmplitudeEvent;
