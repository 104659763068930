export enum AmplitudeEvents {
	campaignButtonClick = 'Campaign button click',
	campaignPageLoaded = 'Campaign page loaded',
	switchTab = 'switch tab',
	manageAccountLanding = 'manage account - landing',
	setParentalControl = 'manage-account set parental control',
	parentalControlSuccess = 'manage-account set parental control success',
	parentalControlError = 'manage-account set parental control error',
	manageAccountRemoveMember = 'manage account - remove member',
	manageAccountAddMemberSuccess = 'manage account - add new member success',
	manageAccountReactivateSuccess = 'manage account - reactivate success',
	manageAccountUnsubscribePlanSuccess = 'manage account - unsubscribe plan success',
	manageAccountRemoveSuccess = 'manage account - remove account success',
	manageAccountRemoveFailure = 'manage account - remove account success',
	manageAccountUpdateCardSuccess = 'manage account - update card success',
	manageAccountBillingSuccess = 'manage account - handle billing case success',
	manageAccountDetails = 'manage account - get account details',
	manageAccountAddMember = 'manage account - add new member',
	selectUpgradePlan = 'manage account - click upgrade card',
	upgradePlanSubmit = 'manage account - submit upgrade card',
	upgradePlanSuccess = 'manage account - success upgrade plan',
	upgradePlanError = 'manage account - error upgrade plan',
	confirmTopUpMobileNumber = 'confirm top up mobile number',
	topupPlanPageLoaded = 'topup plan page loaded',
	selectTopupPlan = 'select topup plan',
	topupProceedToPayment = 'topup proceed to payment',
	cancelationLanding = 'cancelation landing',
	cancelationReasonSelect = 'cancelation reason landing',
	cancelationReasonProceed = 'cancelation reason proceed',
	renewSubscription = 'renew subscription',
	renewSubscriptionFailure = 'manage account - renew subscription failure',
	renewSubscriptionSuccess = 'manage account - renew subscription success',
	formLoaded = 'form loaded',
	formOpenedNotAuthenticated = 'open form not authenticated',
	iqosLandingPagePopupSelect = 'iqos landing page popup select',
	iqosLandingPageButtonClicked = 'iqos landing page button clicked',
	iqosLandingPageLoaded = 'iqos landing page loaded',
	podcastLandingCtaClick = 'podcast landing cta click',
	rsspodcasts_landing = 'podcast rss landing',
	concertLandingLoaded = 'concert landing page loaded',
	concertCTAClick = 'concert cta click',
	tapAppleLogin = 'Tap Apple login',
	loggedInWithapple = 'Logged in with Apple',
	signOut = 'Sign out',
	productsCTAClick = 'products landing cta click',
	redeem_redirect = 'redeem - redirect to payment page',
	showLandingPopup = 'Show landing popup',
	tapLandingPopup = 'Tap landing popup',
	showLandingPageNotPrime = 'Show landing page - not prime',
	tapLandingPageNotPrime = 'Tap landing page - not prime',
	giftLanding = 'Gifts landing page',
	selectGiftCard = 'select gift card',
	selectPlansSection = 'Select Plans\' Section',
	onPlanClick = 'Click plan action',
	viewSubscribe = 'View Subscribe',
	clickOnLandingCTA = 'clickOnLandingCTA',
	mainLanding = 'view landing page',
	successCTAClick = 'success page - cta click',
	copyValentineCode = 'Copy_valentines_code',
	activatePromocode = 'redeem - activate promocode',
	redeemPromocode = 'redeem - redeem promocode',
	redirectPromocode = 'redeem - redirect promocode',
	familyLinkAccepted = 'new member added to family plan',
	reciterchosen = 'Reciter chosen',
	reciterChanged = 'Reciter changed',
	reciterOptout = 'Reciter opt-out',
	reciterTurnOnNotifications = 'Reciter turn on notifications',
	reciterGoToProfile = 'Reciter go to profile',
	resendStudentEmail = 'student resend email',
	editStudentEmail = 'edit student email',
	submitStudentVerification = 'submit student verification page',
	submitStudentVerificationError = 'submit student verification error',
	studentVerificationLoaded = 'student verification page loaded',
	studentEditEmailClick = 'student edit email click',
	studentEmailPageLoaded = 'student email verification page loaded',
	UPDATE_CC_ERROR = 'manage account - failure to update card info',
	UPGRADE_PLAN_STATUS = 'manage account upgrade',
	operatorPageVisit = 'Operator page visit',
	telcoConcertFailure = 'Telco concert page leaded failure',
	telcoConcertSuccess = 'Telco concert page leaded',
	subscriptionSuccess = 'Subscription successful',
	clickVerifyNationalId = 'Click on verify national id',
	thumbraceButtonClick = 'Click notify button',
	applyAutoSubscribe = 'Apply auto subscribe',
	thumbraceCountdownOpen = 'Thumb Race: User lands on the countdown page',
	thumbraceSearchOpen = 'Thumb Race: User lands on the search page',
	thumbraceSelectSong = 'Thumb Race: user chose a song',
	thumbraceVerifyClick = 'Thumb Race: user taps on verify profile details button',
	thumbraceClaimClick = 'Thumb Race: user taps on the claim button',
	thumbraceShareClick = 'Thumb Race: user taps on the share button',
	thumbraceGotoSongClick = 'Thumb Race: user taps on the go to song button',
	cancel_subscription = 'manage account - cancel purchase subscription',
	cancel_subscription_error = 'manage account - cancel purchase subscription failure',
	cancel_subscription_successs = 'manage account - cancel purchase subscription success',
	restart_subscription = 'manage account - restart purchase subscription',
	galaxy_init = 'Galaxy spin the wheel campaign open',
	galaxy_spin_wheel = 'Galaxy spin the wheel campaign start',
	galaxy_register_cta = 'Galaxy spin the wheel campaign register',
	galaxy_claim_prize = 'Galaxy spin the wheel claim prize',
	activationCampaignLanding = 'activation campaign landing',
	activationError = 'activation does not exist/error',
	viewactivation = 'view activation',
	activateCampaignFailure = 'activate campaign failure',
	activateCampaignSuccess = 'activate campaign successful',
	clickActivateCampaign = 'click on activate campaign',
	campaign_register_cta = 'spin the wheel campaign register',
	campaign_init = 'Spin the Wheel Campaign Open',
	campaign_spin_wheel = 'Spin the Wheel Campaign Start',
	campaign_spin_prize = 'Spin The Wheel Campaign Prize',
	campaign_claim_prize = 'Spin The Wheel Campaign Claim Prize',
	visaWCGetCheer = 'Visa WC - get cheer',
	visaWCChooseCountry = 'Visa WC - choose country',
	visaWCUpdateProfile = 'Visa WC - update profile',
	visaWCShare = 'Visa WC - share',
	visaWCCheerGenerated = 'Visa WC - cheer generated',
	countryPickedWorldCup = 'Country picked for world cup',
	countryChangedWorldCup = 'Country changed for world cup',
	optOutWorldCup = 'Opt out for world cup',
	cancelTamaraOfferFailure = 'cancel tamara offer - failure',
	cancelTamaraOfferSuccess = 'cancel tamara offer - success',
	almaraiiSelectPlaylist = 'almaraii select playlist',
	offerMainCTAClick = 'Click on primary button',
	learnMoreCTAClick = 'Click on secondary button',
	clickTamaraWidget = 'Click on Tamara widget',
	valentines23Shared = 'valentines23_shared',
	valentines23PlaylistSelected = 'valentines23_playlist_selected',
	valentines23Result = 'valentines23_result',
	rewardsSelected = 'rewards_selected',
	rewardsDialogueOpened = 'rewards_dialogue_opened',
	rewardsActionSelected = 'rewards_action_selected',
	rewardsUpgradeCTAClicked = 'rewards_upgradecta_clicked',
	downgradeToPlus = 'Downgrade to plus selected',
	downgradedPlanSelected = 'Downgraded plan chosen',
	downgradeSuccess = 'user downgraded successfully',
	openPAIP = 'Open PAIP',
	paipCustomization = 'PAIP customisations made',
	optoutPAIP = 'Opt out of PAIP',
	mojaPageOpen = 'Moja: User Open Page',
	mojaPageCta = 'Moja: User Click on CTA',
	quizInitialPageLoaded = 'Quiz initial page loaded',
	quizQuestionAnswered = 'Quiz question answered',
	quizResultPageLoaded = 'Quiz result page loaded',
	quizResultButtonClicked = 'Quiz result button clicked',
	almaraii_started_creating_a_song = 'almaraii - started creating a song',
	almaraii_user_picked_a_name = 'almaraii - user picked a name',
	almaraii_user_selected_a_theme = 'almaraii - user selected a theme',
	almaraii_user_played_the_song = 'almaraii - user played the song',
	almaraii_user_clicked_on_share = 'almaraii - user clicked on share'
}
