import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './iframe.module.scss';
import { useTranslation } from 'next-i18next';
import { environment } from '@environment';
import reportAmplitudeEvent from '@helpers/analytics';
import { b64DecodeUnicode } from '@helpers/helper';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';

interface IframeProps {
	iframeSrc: string;
	onCloseIframe: any;
}

export const Iframe = ({ iframeSrc, onCloseIframe }: IframeProps) => {
	const { t } = useTranslation();
	const [hideIframe, setHideIframe] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isInitIframe, setIsInitIframe] = useState(false);
	const [error, setError] = useState(null);
	let iframeElt;
	// let interval;

	const closeIframeModal = () => {
		reportAmplitudeEvent(AmplitudeEvents.closeiframe, {});
		onCloseIframe({});
	};

	const initIframe = () => {
		reportAmplitudeEvent(AmplitudeEvents.iframeLanding, {});
		iframeElt = document.getElementById('paymentIframe');
		if (iframeElt && iframeElt !== null) {
			setIsInitIframe(true);
			iframeElt.onload = () => {
				reportAmplitudeEvent(AmplitudeEvents.iframeLoaded, {
					iframeSrc
				});
				setIsLoading(false);
				iframeElt.contentWindow.postMessage('give_me_location_href', '*');
			};

			window.addEventListener('message', messageListener, false);
		}
	};

	const messageListener = (event) => {
		const path = event.data;
		if (path && path !== null && typeof path === 'string') {
			handling3dsUrl(path);
		}
	};

	const handling3dsUrl = (path) => {
		if (path && path !== null && typeof path === 'string') {
			const str = path.split('?');
			const strArr = str.length > 1 ? str[1].split('&') : [];
			const extraparams: any = {};
			if (strArr.length > 0) {
				strArr.forEach((elt) => {
					const eltsplit = elt.split('=').length >= 2 ? elt.split('=') : null;
					if (eltsplit !== null) {
						extraparams[eltsplit[0]] = eltsplit[1];
					}
				});
			}

			if (extraparams) {
				if (extraparams.message && extraparams.message !== '') {
					extraparams.message = decodeURIComponent(b64DecodeUnicode(extraparams.message));
				}

				if (extraparams.vouchers_encoded && extraparams.vouchers_encoded !== '') {
					extraparams.vouchers_encoded = atob(decodeURIComponent(extraparams.vouchers_encoded));
				}

				if (extraparams.terms_text_encoded && extraparams.terms_text_encoded !== '') {
					extraparams.terms_text_encoded = atob(decodeURIComponent(extraparams.terms_text_encoded));
				}

				if (extraparams.terms_link_encoded && extraparams.terms_link_encoded !== '') {
					extraparams.terms_link_encoded = atob(decodeURIComponent(extraparams.terms_link_encoded));
				}

				if (extraparams.success_subtitle && extraparams.success_subtitle !== '') {
					extraparams.success_subtitle = atob(decodeURIComponent(extraparams.success_subtitle));
				}

				if (extraparams.button_text && extraparams.button_text !== '') {
					extraparams.button_text = atob(decodeURIComponent(extraparams.button_text));
				}

				if (extraparams.button_url && extraparams.button_url !== '') {
					extraparams.button_url = atob(decodeURIComponent(extraparams.button_url));
				}
			}

			if (path?.includes('3dsecure/interface?success=1')) {
				setTimeout(() => {
					cancel('onthreeDPaymentSuccess', path, extraparams);
				}, 500);
			} else if (path?.includes('3dsecure/interface?success=visaenbd')) {
				setTimeout(() => {
					cancel('onthreeDVisaPaymentSuccess', path, extraparams);
				}, 500);
			} else if (path?.includes('3dsecure/interface?fail=1')) {
				showIframeError(extraparams);
				setTimeout(() => {
					cancel('onthreeDPaymentFail', path, extraparams);
				}, 500);
			}
		}
	};

	const showIframeError = (params) => {
		setHideIframe(true);
		setError(params.message);
	};

	const cancel = (action, path, params?) => {
		reportAmplitudeEvent(AmplitudeEvents.iframeresult, {
			resultkey: action,
			resultpath: path,
			params: params && params !== null ? JSON.stringify(params) : undefined
		});
		const result = {
			action,
			params
		};
		onCloseIframe(result);
	};

	useEffect(() => {
		if (!isInitIframe) {
			initIframe();
		}
	});

	return (
		<>
			{/* <div className={`wrap ${hideIframe ? 'hideiframe' : 'defaultiframe'}`}> */}
			<div className={`${styles.wrap} ${styles.defaultiframe}`}>
				<div className={styles['iframe-head-wrap']}>
					{!hideIframe && (
						<div className={styles['iframe-info']}>
							<span>{t('verification_3dsecure')}</span>
						</div>
					)}
					<div
						className={styles['close-iframe']}
						onClick={() => {
							closeIframeModal();
						}}
					>
						{' '}
						<div className={styles.mdiv}>
							<div className={styles.md} />
						</div>
					</div>
				</div>
				{isLoading && !hideIframe ? (
					<div className={styles['loading-iframe']}>
						<img
							src={environment.images_cdn_url + '/iframe-loader.gif'}
							style={{ width: '2em', height: '2em' }}
						/>
						<span className={styles['iframe-wait']}>{t('iframe_wait')}</span>
					</div>
				) : null}
				{!hideIframe && (
					<iframe
						className={styles.overflowScroll}
						src={iframeSrc}
						id="paymentIframe"
						height="100%"
						width="100%"
						frameBorder="0"
						scrolling="yes"
					/>
				)}
				{error && (
					<div className="flexbox colls w100" style={{ justifyContent: 'center', height: '100%' }}>
						<div className={styles['iframe-error']}>{error}</div>
						<div style={{ padding: '1em 0' }}>{t('close_iframe')}</div>
					</div>
				)}
			</div>
		</>
	);
};

Iframe.propTypes = {
	iframeSrc: PropTypes.string.isRequired
};

export default Iframe;
