import React, { useEffect, useState } from 'react';
import DialogComponent from '@components/dialog/dialog';
import { gateway } from '@helpers/neogateway';
import { AxiosResponse } from 'axios';
import { handleDeeplink } from '@helpers/deeplinks';

export default function DialogsWrapper() {
	const [dialogData, setDialogData] = useState(null);

	useEffect(() => {
		if (!gateway) {
			return;
		}

		gateway.customResponseInterceptor.on('api_response_success', (response: AxiosResponse) => {
			const { dialog } = response.data;

			if (dialog) {
				setDialogData({
					displaymode: 'custom',
					head: {
						text: dialog.objectdata.title
					},
					body: {
						text: dialog.objectdata.subtitle
					},
					buttons: [
						{
							label: dialog.objectdata.buttontext,
							styles: {
								color: 'white',
								backgroundColor: '#8d00f5',
								fontSize: '0.9em',
								padding: '0.8em 2em'
							},
							type: dialog.objectdata.buttonurl
						}
					]
				});
			}
		});
	}, [gateway]);

	const handleButtonClick = (data: any) => {
		setDialogData(null);
		handleDeeplink(data.type);
	};

	return dialogData ? (
		<DialogComponent data={dialogData} onButtonClick={handleButtonClick} />
	) : null;
}
