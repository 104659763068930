/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable no-mixed-spaces-and-tabs */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import styles from './payment-form.module.css';
import ccValidator from '@helpers/validator';
import { ErrorNotice } from '../error/error';
import reportAmplitudeEvent from '@helpers/analytics';
import { AmplitudeEvents } from '@anghami/neoanalytics/src/enums/events';
import { submitPayment, fetchCardData, isCheckoutCard } from '@helpers/payment-helper';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { Iframe } from '../iframe/iframe';
import { environment } from '@environment';

interface PaymentFormProps {
	type: string;
	data: any;
	isReady?: boolean;
	onSubmit: any;
	onSuccess: any;
}

const AMPLITUDE_EVENTS = {
	UPDATE_CC_ERROR: 'manage account - failure to update card info',
	UPDATE_CC_SUCCESS: 'manage account - success update card info'
};

const Transition = React.forwardRef(
	(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>
	) => {
		return <Slide ref={ref} direction="up" {...props} />;
	}
);

export const PaymentForm = ({ type, data, isReady, onSubmit, onSuccess }: PaymentFormProps) => {
	const { t, i18n } = useTranslation();
	const { language } = i18n;
	const [cardInfo, setCardInfo]: any = useState({});
	const [errors, setErrors]: any = useState({});
	const [fieldErrors, setFieldErrors]: any = useState({});
	const [cardHolderName, setCardHolderName] = useState('');
	const [cardNumber, setCardNumber] = useState('');
	const [ccMonth, setCCMonth] = useState('');
	const [ccYear, setCCYear] = useState('');
	const [CVV, setCVV] = useState('');
	const [isValidForm, setIsValidForm] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [iframeSrc, setIframeSrc] = useState(null);
	const [ctaText, setCtaText] = useState(null);
	const [isUpgrade, setIsUpgrade] = useState(false);
	let errorRef = React.useRef<HTMLInputElement>(null);

	const handleFormInputChange = (type, event) => {
		resetPaymentError();
		switch (type) {
			case 'card_holder_name':
				cardNameChange(event);
				return;
			case 'ccnumber':
				cardNumberChange(event);
				return;
			case 'ccmonth':
				handleMonthInputChange(event);
				return;
			case 'ccyear':
				handleYearInputChange(event);
				return;
			case 'cvv':
				changeCVV(event);
				break;
			default:
		}
	};

	const resetPaymentError = () => {
		setErrors({
			...errors,
			payment_err: null
		});
	};

	const validateForm = () => {
		let isValid = isCardFieldsValid();
		Object.keys(errors).forEach((key) => {
			if (errors[key] && key !== 'payment_err') {
				isValid = false;
				scrollToRef();
			}
		});
		setIsValidForm(isValid);
		return isValid;
	};

	/**
	 * @description helper method that checks if all required fields are filled in card form
	 */
	const isCardFieldsValid = () => {
		const errors: any = {};
		let isValid = true;
		if (cardInfo && cardInfo !== null && Object.keys(cardInfo).length > 0) {
			Object.keys(cardInfo).forEach((key) => {
				if (!cardInfo[key] || cardInfo[key] === null || cardInfo[key] === '') {
					isValid = false;
				}
			});
		} else {
			isValid = false;
		}

		if (isValid) {
			const updatedErros = Object.keys(errors).forEach((key) => {
				if (key !== 'payment_err') {
					errors[key] = null;
					errors.requiredfields = null;
				}
			});
			setErrors(updatedErros);
		} else {
			setFieldErrors({
				cardExMonth: t('required_field'),
				cardExYear: t('required_field'),
				cardNumber: t('required_field'),
				cvv: t('required_field'),
				cardholderName: t('required_field')
			});
			checkErrors('requiredfields', t('required_field'), isValid);
		}

		return isValid;
	};

	const cardNameChange = (event: any) => {
		// only accept characters and spaces /^[a-zA-Z\-\. ]+$/;
		if (
			/^[a-zA-Z\-\. ]+$/.test(event.target.value) ||
			event.target.value === '' ||
			/^[\u0621-\u064A\040]+$/.test(event.target.value)
		) {
			setCardFormValues(event.target.name, event.target.value);
		}

		const cardholder = event.target.value ? null : t('cc_name_required');
		setFieldErrors({
			...fieldErrors,
			cardholderName: cardholder
		});
		checkErrors(
			'requiredfields',
			errors.requiredfields === null ? cardholder : errors.requiredfields,
			cardholder === null
		);
	};

	const cardNumberChange = (event: any) => {
		// Only accept numbers and spaces
		if (/^[\d ]*$/.test(event.target.value)) {
			setCardFormValues(event.target.name, ccValidator.formatCardNumber(event.target.value));
		}
	};

	const handleMonthInputChange = (event: any) => {
		if (
			/^[0-9]{0,2}$/.test(event.target.value) &&
			(event.target.value === '' || Number(event.target.value) <= 12) &&
			event.target.value !== '00'
		) {
			setCardFormValues(event.target.name, event.target.value);
		}
	};

	const handleYearInputChange = (event: any) => {
		if (
			(/^[0-9]{0,2}$/.test(event.target.value) && Number(event.target.value) !== 0) ||
			event.target.value === ''
		) {
			setCardFormValues(event.target.name, event.target.value);
		}
	};

	const changeCVV = (event: any) => {
		// only accept numbers and less then 1000
		const _val = event.target.value;
		if (/^\d{0,4}$/.test(_val) || _val === '') {
			setCardFormValues(event.target.name, _val);
			validateCVV(event);
		}
	};

	const checkDateExpiry = (cardmonth) => {
		if (
			cardmonth &&
			cardInfo.ccyear &&
			String(cardmonth).length > 0 &&
			String(cardInfo.ccyear).length > 0
		) {
			// comparing current date, with the last day of month of expiry (added 1 month, with first day)
			let selectedDate: any;
			const currentDate: any = new Date();
			const currentYear = new Date().getFullYear();
			const yearFirstDigits = String(currentYear).slice(0, 2);

			if (Number(cardmonth) === 12) {
				selectedDate = new Date(yearFirstDigits + (Number(cardInfo.ccyear) + 1) + '/1/1');
			} else {
				selectedDate = new Date(
					yearFirstDigits + cardInfo.ccyear + '/' + (Number(cardmonth) + 1) + '/1'
				);
			}

			return currentDate - selectedDate > 0;
		}

		return false;
	};

	const setCardFormValues = (type, value) => {
		updateInputState(type, value);
		switch (type) {
			case 'card_holder_name':
				setCardHolderName(value);
				return;
			case 'ccnumber':
				setCardNumber(value);
				return;
			case 'ccmonth':
				setCCMonth(value);
				return;
			case 'ccyear':
				setCCYear(value);
				return;
			case 'cvv':
				setCVV(value);
				break;
			default:
		}
	};

	const cardNumberValidate = (event) => {
		// this.removeValidity(event);
		if (event.target.value) {
			const res = ccValidator.validateCardNumber(event.target.value);
			if (res && res.cardtype && cardInfo.ccnumber.length > 17) {
				setFieldErrors({
					...fieldErrors,
					cardNumber: null
				});
				checkErrors('cardNumber', null);
			} else {
				setFieldErrors({
					...fieldErrors,
					cardNumber: t('invalid_card_number')
				});
				checkErrors('cardNumber', t('invalid_card_number'));
			}
		} else {
			setFieldErrors({
				...fieldErrors,
				cardNumber: null
			});
			checkErrors('cardNumber', null);
		}
	};

	const monthInputBlur = () => {
		// this.removeValidity(event);
		const ccmonth = Number(cardInfo.ccmonth);
		let cardmonth = null;
		if (ccmonth && String(ccmonth).length === 1 && ccmonth !== 0 && ccmonth < 9) {
			cardmonth = '0' + ccmonth;
		} else if (ccmonth === 0) {
			cardmonth = '01';
		}

		if (cardmonth) {
			setCardFormValues('ccmonth', cardmonth);
		}

		const expired = checkDateExpiry(cardmonth);
		const expiredYear = expired ? t('expired') : null;
		setFieldErrors({
			...fieldErrors,
			cardExYear: expiredYear,
			cardExMonth: expired ? t('expired') : null
		});
		checkErrors('cardExMonth', expiredYear, !expiredYear);
	};

	const yearInputBlur = (event) => {
		// this.removeValidity(event);
		let selectedYear = event.target.value;
		let yearIssue = true;
		const currentYear = new Date().getFullYear();
		const expired = checkDateExpiry(cardInfo.ccmonth);

		if (!event.target.value && !cardInfo.ccmonth) {
			yearIssue = false;
		} else if (
			String(event.target.value).length === 2 &&
			Number('20' + String(event.target.value)) < currentYear + 20 &&
			Number('20' + String(event.target.value)) >= currentYear
		) {
			selectedYear = '20' + String(event.target.value);
			yearIssue = false;
		} else if (
			String(event.target.value).length === 4 &&
			Number(event.target.value) < currentYear + 20
		) {
			selectedYear = String(event.target.value);
			yearIssue = false;
		}

		const expiredYear = yearIssue ? t('invalid_year') : expired ? t('expired') : null;
		const expiredMonth = yearIssue ? null : expired ? t('expired') : null;
		setFieldErrors({
			...fieldErrors,
			cardExYear: expiredYear,
			cardExMonth: expiredMonth
		});
		checkErrors('cardExYear', expiredYear, expiredYear === null ? true : Boolean(expiredYear));
		setCardInfo({
			...cardInfo,
			ccyear: selectedYear.length === 4 && !yearIssue ? selectedYear.slice(2, 4) : selectedYear
		});
	};

	const validateCVV = (event: any) => {
		// this.removeValidity(event);
		// only accept numbers and less then 1000
		const validation = /^[0-9]{3,4}$/.test(event.target.value);
		const cvv = validation ? null : t('invalid_cvv');
		setFieldErrors({
			...fieldErrors,
			cvv
		});
		checkErrors('cvv', cvv);
	};

	const updateInputState = (name, value) => {
		const card = { ...cardInfo };
		card[name] = String(value);
		setCardInfo(card);
	};

	const scrollToRef = () => {
		if (window && errorRef && errorRef.current) {
			window.scrollTo(0, errorRef.current.offsetTop - 120);
		}
	};

	const submit = async (paymentData) => {
		if (!isLoading) {
			const isValid = validateForm();
			if (isValid) {
				setIsLoading(true);
				reportAmplitudeEvent(AmplitudeEvents.clickSubmitPayment, {});
				switch (type) {
					case 'update_card':
						handleCCPayment(
							{
								...data,
								card: paymentData.data ? paymentData.data : null
							},
							'update_card',
							'update credit card'
						);
						break;
					case 'upgrade_plan': {
						fetchAndSubmitCard(paymentData, 'save_cc_info');
						setIsLoading(false);
						break;
					}

					case 'redeem': {
						handleCCPayment(
							{
								...data,
								...data.checkoutData,
								card: paymentData.data ? paymentData.data : null
							},
							'redeem_promocode',
							'redeem promocode'
						);
						break;
					}

					default:
						break;
				}
			} else {
				onSubmit({
					type: 'save_cc_info',
					card: null
				});
			}
		}
	};

	const fetchAndSubmitCard = async (paymentData, type) => {
		let cardDtls: any = {};
		if (paymentData && paymentData.data) {
			cardDtls = setCardDetails({
				...data,
				card: paymentData.data
			});
			const cardResponse: any = await fetchCardData(cardDtls, data.planid, data.creditcard_payment);
			onSubmit({
				card: cardResponse,
				planid: data.planid,
				merchant_id: cardResponse?.card?.country,
				type
			});
		}
	};

	const setCardDetails = (data) => {
		const isMadaCard = isCheckoutCard(data?.card);
		return {
			...data.card,
			ccnumber:
				data.card.ccnumber && data.card.ccnumber !== null
					? data.card.ccnumber.replace(/\s/g, '').trim()
					: null,
			country:
				isMadaCard && data?.creditcard_country !== 'XX'
					? 'SA'
					: Number(data.creditcard_payment) === 53
						? data.creditcard_country
						: null
		};
	};

	/**
	 * @description handles different types of cc methods: updating card, saving card info for later purchases
	 * through promocode (redeem), etc
	 */
	const handleCCPayment = (res, type, amplitudetype) => {
		reportAmplitudeEvent(AmplitudeEvents.submitPayment, {
			payment_method: amplitudetype,
			planid: res.planid
		});
		const cardDetails = setCardDetails(res);
		submitPayment(
			{
				card: cardDetails,
				planid: res.planid,
				cardPayment: res.creditcard_payment,
				promocode: res.promocode,
				userappsid: res.appsid,
				country: res.creditcard_country
			},
			type
		).then((res: any) => {
			setIsLoading(false);
			if (res?.error) {
				let errmsg = '';
				if (res.error.errors?.length > 0) {
					res.error.errors.forEach((err: any) => {
						errmsg += err + ',';
					});
					errmsg = errmsg.substring(0, errmsg.length - 1);
				} else {
					errmsg = res?.error?.message !== '' ? res.error.message : t('oops_tryagain');
				}

				reportAmplitudeEvent(AMPLITUDE_EVENTS.UPDATE_CC_ERROR, {
					code: res.error.code,
					message: errmsg
				});

				setErrors({
					payment_err: errmsg
				});

				// TODO: should add dialog here
				if (res.error.iframeurl) {
					handle3dsIframe(res.error.iframeurl);
				}
			} else if (res?.iframe_url) {
				handle3dsIframe(res.iframe_url);
			} else {
				onSuccess({ type: `${type}_success`, res });
			}
		});
	};

	const handle3dsIframe = (url) => {
		reportAmplitudeEvent(AmplitudeEvents.openiframe, { iframesrc: url });
		setIframeSrc(url);
		setTimeout(() => {
			setShowDialog(true);
		});
	};

	const handleIframeClose = (result) => {
		// TODO: handle iframe closing result if success, failure, etc
		const params = result && result !== null && result.params ? result.params : null;
		if (result.action !== 'onthreeDPaymentFail') {
			setShowDialog(false);
		}

		if (params !== null) {
			const _type =
				params && params.success && Number(params.success) === 1
					? `${type}_success`
					: `${type}_failure`;
			if (Number(params.isupdate) === 1 && Number(params.success) === 1) {
				reportAmplitudeEvent(AMPLITUDE_EVENTS.UPDATE_CC_SUCCESS);
			}

			setErrors({});
			onSuccess({ type: _type, result });
		}
	};

	const handleCTAText = () => {
		switch (type) {
			case 'update_card':
				return t('Update');
			case 'upgrade_plan':
				setIsUpgrade(true);
				break;
			case 'redeem':
				return data && data.button_text ? data.button_text : t('Submit');
			default:
				return t('Submit');
		}
	};

	const resetDateValidation = (arg, val, errors) => {
		Object.keys(errors).forEach((errKey) => {
			if (val === errors[errKey] && arg !== errKey) {
				errors[errKey] = null;
			}
		});
		return errors;
	};

	const checkErrors = (arg, val, isValidFields?) => {
		const updatedErrors = {
			...resetDateValidation(arg, val, errors)
		};
		updatedErrors[arg] = val;
		// this check is done because month/year inputs are attached to each other,
		// if month is fixed year will still hold the error msg unless its set here
		// to null and same for month
		if (isValidFields) {
			if (arg === 'cardExMonth') {
				updatedErrors.cardExYear = null;
			} else if (arg === 'cardExYear') {
				updatedErrors.cardExMonth = null;
			}
		}

		const fieldsErrorCond =
			isValidFields === undefined
				? Boolean(
					Object.keys(fieldErrors).length > 0 &&
							Object.keys(fieldErrors).find((elt) => fieldErrors[elt] !== null)
				  )
				: isValidFields;
		setErrors({
			...updatedErrors,
			payment_err: null,
			requiredfields: Boolean(errors) && fieldsErrorCond ? null : updatedErrors.requiredfields
		});
	};

	useEffect(() => {
		if (type) {
			setCtaText(handleCTAText());
		}

		const fieldErrorsCond =
			Boolean(fieldErrors) &&
			Object.keys(fieldErrors).length > 0 &&
			Object.keys(fieldErrors).find((key) => fieldErrors[key] !== null);
		const errorsCond =
			Boolean(errors) &&
			Object.keys(errors).length > 0 &&
			Object.keys(errors).find((key) => Boolean(errors[key]));
		if (fieldErrorsCond || errorsCond || Object.keys(cardInfo).length < 5) {
			setIsValidForm(false);
		} else {
			setIsValidForm(true);
		}
	}, [cardInfo, errors, type]);

	useEffect(() => {
		if (isReady) {
			submit({ type, data: cardInfo });
		}
	}, [isReady]);

	errorRef = React.createRef();

	return (
		<div className={styles['payment-form-wrapper']}>
			<div ref={errorRef}>
				{(!isValidForm ||
					(isValidForm && errors && Object.keys(errors).find((key) => key === 'payment_err'))) &&
					Object.keys(errors).map((errKey) => {
						return errors[errKey] !== null &&
							errors[errKey] !== '' &&
							typeof errors[errKey] === 'string' ? (
								<div key={errKey}>
									<ErrorNotice
										className="err-notice-payment"
										language={language}
										message={errors[errKey]}
									/>
								</div>
							) : null;
					})}
			</div>
			<div id="payform" className={`${styles['card-holder-wrap']} ${styles['main-padding']}`}>
				<div className={styles.label}>{t('Card holder name')}</div>
				<input
					required
					type="text"
					className={`w100 ${
						fieldErrors.cardholderName && fieldErrors.cardholderName !== null ? styles.warning : ''
					}`}
					placeholder={t('Full name')}
					value={cardHolderName}
					name="card_holder_name"
					id="cardholder-dense"
					onChange={(e) => {
						handleFormInputChange('card_holder_name', e);
					}}
				/>
				<div className={`${styles['card-number-wrap']} ${styles['main-padding']}`}>
					<div className={styles.label}>{t('Card number')}</div>
					<input
						required
						type="text"
						placeholder="•••• •••• •••• ••••"
						name="ccnumber"
						id="ccnumber-dense"
						value={cardNumber}
						className={`w100 ${
							fieldErrors.cardNumber && fieldErrors.cardNumber !== null ? styles.warning : ''
						}`}
						onChange={(e) => {
							handleFormInputChange('ccnumber', e);
						}}
						onBlur={(e) => {
							cardNumberValidate(e);
						}}
					/>
				</div>
				<div className={`flexbox corners ${styles['main-padding']}`}>
					<div className={styles['card-month-wrap']}>
						<div className={styles.label}>{t('month')}</div>
						<input
							required
							type="text"
							placeholder={t('cc_payment_month')}
							name="ccmonth"
							id="month-dense"
							value={ccMonth}
							className={fieldErrors.cardExMonth && fieldErrors.cardExMonth !== null ? styles.warning : ''}
							onChange={(e) => {
								handleFormInputChange('ccmonth', e);
							}}
							onBlur={() => {
								monthInputBlur();
							}}
						/>
					</div>
					<div className={styles['card-year-wrap']}>
						<div className={styles.label}>{t('year')}</div>
						<input
							required
							type="text"
							placeholder={t('cc_payment_year')}
							name="ccyear"
							id="year-dense"
							value={ccYear}
							className={
								fieldErrors.cardExMonth === true ||
								(fieldErrors.cardExYear &&
									fieldErrors.cardExYear !== null &&
									fieldErrors.cardExYear !== '')
									? styles.warning
									: ''
							}
							onChange={(e) => {
								handleFormInputChange('ccyear', e);
							}}
							onBlur={(e) => {
								yearInputBlur(e);
							}}
						/>
					</div>
					<div className={styles['card-cvv-wrap']}>
						<div className={styles.label}>{t('cc_payment_cvv_title')}</div>
						<input
							required
							type="text"
							placeholder={t('cc_payment_cvv')}
							name="cvv"
							id="cvv-dense"
							value={CVV}
							className={
								fieldErrors.cvv && fieldErrors.cvv !== null && fieldErrors.cvv !== ''
									? styles.warning
									: ''
							}
							onChange={(e) => {
								handleFormInputChange('cvv', e);
							}}
						/>
					</div>
				</div>
				<div className={`${styles['payment-footer']} flexbox corners`}>
					<div className="flexbox">
						<img
							className={styles.shield}
							src={`${environment.web2_images_cdn_url}/shield.svg`}
							alt="shield"
						/>
						<span style={{ fontSize: '0.8em', padding: '0 0.75em' }}>
							{' '}
							{t('cc_payment_secure')}{' '}
						</span>
					</div>
					<img
						src={environment.web_cdn_url + 'web/assets/img/payment/payment-methods-updated.png'}
						className={styles['payment-methods']}
						alt="payment-methods"
					/>
				</div>
				{!isUpgrade && (
					<div className="flexbox centered">
						<button
							type="button"
							className={`${styles['default-btn']} default-btn ${
								isValidForm ? '' : styles.disabled
							} ${isLoading ? 'loading' : ''}`}
							id="payment-id-btn"
							onClick={() => {
								if (isValidForm) {
									submit({ type, data: cardInfo });
								}
							}}
						>
							{isLoading ? (
								<img
									className={styles['op-loader']}
									src={environment.web_cdn_url + 'web2/assets/img/plus/op-loader.gif'}
								/>
							) : (
								ctaText
							)}
						</button>
					</div>
				)}
			</div>
			{showDialog && (
				<Dialog fullScreen open={showDialog} TransitionComponent={Transition}>
					<Iframe iframeSrc={iframeSrc} onCloseIframe={handleIframeClose} />
				</Dialog>
			)}
		</div>
	);
};

PaymentForm.propTypes = {
	type: PropTypes.string.isRequired,
	data: PropTypes.any.isRequired
};

export default PaymentForm;
