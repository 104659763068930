import { environment } from '@environment';
export const benefitsAssets = {
	mastercard: {
		download: environment.web_cdn_url + 'web/assets/img/features/orange/downloads.png',
		noads: environment.web_cdn_url + 'web/assets/img/features/orange/no-ads.png',
		highQuality: environment.web_cdn_url + 'web/assets/img/features/orange/audio-quality.png',
		lyrics: environment.web_cdn_url + 'web/assets/img/features/orange/lyrics.png',
		shuffle: environment.web_cdn_url + 'web/assets/img/features/orange/rewind.png',
		live: environment.web_cdn_url + 'web/assets/img/features/orange/liveRadio.png'
	},
	studentoffer: {
		download: environment.images_cdn_url + '/cancel/downloads.png',
		noads: environment.images_cdn_url + '/cancel/noads.png',
		highQuality: environment.images_cdn_url + '/cancel/audioquality.png',
		lyrics: environment.images_cdn_url + '/cancel/lyrics.png',
		shuffle: environment.images_cdn_url + '/cancel/rewind.png',
		live: environment.images_cdn_url + '/cancel/live.png'
	},
	corporateoffer: {
		download: environment.web_cdn_url + 'web/assets/img/corporateoffer/benefits/download.png',
		noads: environment.web_cdn_url + 'web/assets/img/corporateoffer/benefits/noads.png',
		highQuality:
			environment.web_cdn_url + 'web/assets/img/corporateoffer/benefits/audioquality.png',
		lyrics: environment.web_cdn_url + 'web/assets/img/corporateoffer/benefits/lyrics.png',
		shuffle: environment.web_cdn_url + 'web/assets/img/corporateoffer/benefits/rewind.png',
		live: environment.web_cdn_url + '_next/static/images/corporateoffer/live.png'
	},
	redeem_landing: {
		download: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/downloads.png',
		noads: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/noads.png',
		highQuality: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/hq.png',
		lyrics: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/lyrics.png',
		shuffle: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/shuffle.png',
		live: environment.web_cdn_url + 'web/assets/img/redeem-landing/benefits/liveradio.png'
	}
};

export const benefitsTitle = {
	corporateoffer: 'corporate_plus_features_title',
	redeem_landing: 'redeem_plus_features_title',
	default: 'allfeatures_title'
};

export const benefitsStyles = {
	gifts: 'purple',
	redeem_landing: 'green'
};

export const progressBarColors = {
	mastercard: {
		default: 'black',
		highlight: '#F79E1B',
		textDefault: 'white',
		textHighlight: 'white'
	}
};

export const metas = {
	mastercard: {
		en: {
			title: 'Get 3 months Anghami Plus for free',
			description: 'Subscribe with your MasterCard',
			image: `${environment.phoenix_url}/adminupload/mastercard_metasEN.jpeg`
		},
		ar: {
			title: 'احصل على ٣ أشهر أنغامي بلس مجاناً',
			description: 'اشترك ببطاقة ماستركارد',
			image: `${environment.phoenix_url}/adminupload/mastercard_metasAR.jpeg`
		},
		fr: {
			title: 'Obtiens 3 mois gratuits Anghami Plus',
			description: 'Abonne-toi avec ta MasterCard',
			image: `${environment.phoenix_url}/adminupload/mastercard_metasFR.jpeg`
		}
	}
};

export const seo = {
	mastercard: {
		ar: `<NextSeo
				title="احصل على ٣ أشهر أنغامي بلس مجاناً"
				description="اشترك ببطاقة ماستركارد"
				canonical="https=//www.anghami.com/mastercard"
				openGraph={{
					url: 'https://www.anghami.com/mastercard',
					title="احصل على ٣ أشهر أنغامي بلس مجاناً",
					description="اشترك ببطاقة ماستركارد",
					images: [
						{
							url: 'https://phoenix.anghcdn.co/adminupload/mastercard_metasAR.jpeg',
							alt: 'احصل على ٣ أشهر أنغامي بلس مجاناً'
						}
					],
					site_name: 'Anghami'
				}}
				twitter={{
					handle: '@anghami',
					site: '@anghami',
					cardType: 'summary_large_image'
				}}/>`,
		en: `<NextSeo
				title="Get 3 months Anghami Plus for free"
				description="Subscribe with your MasterCard"
				canonical="https=//www.anghami.com/mastercard"
				openGraph={{
					url: 'https://www.anghami.com/mastercard',
					title="Get 3 months Anghami Plus for free",
					description="Subscribe with your MasterCard",
					images: [
						{
							url: 'https://phoenix.anghcdn.co/adminupload/mastercard_metasEN.jpeg',
							alt: 'Get 3 months Anghami Plus for free'
						}
					],
					site_name: 'Anghami'
				}}
				twitter={{
					handle: '@anghami',
					site: '@anghami',
					cardType: 'summary_large_image'
				}}/>`,
		fr: `<NextSeo
				title="Obtiens 3 mois gratuits Anghami Plus"
				description="Abonne-toi avec ta MasterCard"
				canonical="https=//www.anghami.com/mastercard"
				openGraph={{
					url: 'https://www.anghami.com/mastercard',
					title="Obtiens 3 mois gratuits Anghami Plus",
					description="Abonne-toi avec ta MasterCard",
					images: [
						{
							url: 'https://phoenix.anghcdn.co/adminupload/mastercard_metasFR.jpeg',
							alt: "Obtiens 3 mois gratuits Anghami Plus"
						}
					],
					site_name: 'Anghami'
				}}
				twitter={{
					handle: '@anghami',
					site: '@anghami',
					cardType: 'summary_large_image'
				}}/>`
	}
};

export const checkoutCards = [
	'242030',
	'403024',
	'406136',
	'406996',
	'407520',
	'409201',
	'410621',
	'410685',
	'410834',
	'412565',
	'417633',
	'419593',
	'420132',
	'421141',
	'422817',
	'422818',
	'422819',
	'428331',
	'428671',
	'428672',
	'428673',
	'431361',
	'432328',
	'434107',
	'439954',
	'440533',
	'440647',
	'440795',
	'442463',
	'445564',
	'446393',
	'446404',
	'446672',
	'455036',
	'455708',
	'457865',
	'457997',
	'458456',
	'462220',
	'468540',
	'468541',
	'468542',
	'468543',
	'474491',
	'483010',
	'483011',
	'483012',
	'484783',
	'486094',
	'486095',
	'486096',
	'489318',
	'489319',
	'504300',
	'513213',
	'515079',
	'516138',
	'520058',
	'521076',
	'524130',
	'524514',
	'524940',
	'529415',
	'529741',
	'530060',
	'531196',
	'535825',
	'535989',
	'536023',
	'537767',
	'543085',
	'543357',
	'549760',
	'554180',
	'555610',
	'558563',
	'588845',
	'588848',
	'588850',
	'589206',
	'604906',
	'605141',
	'636120',
	'968201',
	'968202',
	'968203',
	'968204',
	'968205',
	'968206',
	'968207',
	'968208',
	'968209',
	'968211',
	'22337902',
	'22337986',
	'22402030',
	'40719700',
	'40739500',
	'45488707',
	'49098000',
	'52166100',
	'53973776'
];

/**
 * @description data represents the array of information found in each step whether its an input, drop down, etc
 * @description action inside data array represens the cta action, api represents the endpoint to be called
 */
export const stepperTypes: any = {
	'rss-podcasts': {
		steps: [
			{
				titleKey: 'podcastrss_step1_title',
				body: 'podcastrss_step1_desc',
				details: [
					{
						param: 'url',
						type: 'input_action',
						input_type: 'text',
						input_label: 'podcastrss_step1_rss',
						input_placeholder: 'rsspodcasts_add_rss_feed_link_placeholder',
						regexCondition:
							/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
					}
				],
				cta: {
					api: 'POSTaddrss',
					labelKey: 'podcastrss_step1_cta'
				}
			},
			{
				titleKey: 'podcastrss_step2_title',
				body: 'podcastrss_step2_desc',
				details: [
					{
						param: 'podcast_id',
						type: 'action_link',
						textKey: 'podcastrss_step2_resend',
						color: '#563594',
						action: 'POSTaddrss',
						is_resend: true
					},
					{
						param: 'code',
						type: 'input_action',
						condition: 'text',
						input_label: 'podcastrss_step2_code',
						input_placeholder: ''
					}
				],
				cta: {
					api: 'POSTverifyrssemail',
					labelKey: 'podcastrss_step3_cta'
				}
			},
			{
				titleKey: 'podcastrss_step3_title',
				body: 'podcastrss_step3_desc',
				details: [
					{
						param: 'categories',
						type: 'dropdown',
						label: 'podcastrss_step3_genre',
						placeholder: 'choose_genre',
						isMulti: true,
						maxOptions: 3,
						endpoint: {
							type: 'getRSSOptions',
							params: {
								options_type: 'categories'
							}
						}
					},
					{
						param: 'country',
						type: 'dropdown',
						label: 'podcastrss_step3_country',
						placeholder: 'choose_country',
						isMulti: false,
						endpoint: {
							type: 'getRSSOptions',
							params: {
								options_type: 'countries'
							}
						}
					},
					{
						param: 'language',
						type: 'dropdown',
						label: 'podcastrss_step3_language',
						placeholder: 'choose_language',
						isMulti: false,
						endpoint: {
							type: 'getRSSOptions',
							params: {
								options_type: 'languages'
							}
						}
					},
					{
						param: 'explicit',
						type: 'checkbox',
						label: 'podcastrss_step3_explicit',
						value: false
					}
				],
				cta: {
					api: 'POSTsubmitrss',
					labelKey: 'podcastrss_step3_cta'
				}
			}
		],
		ctaStyle: {
			backgroundColor: '#FEEEB6',
			color: '#563594'
		},
		ctaLoader: environment.new_web_cdn_url + '/web/assets/img/loaders/cta-loader-purple.gif'
	}
};

export const successPageData = {
	default: {
		background: {
			default: `transparent linear-gradient(180deg, #00BDFE 0%, #0031F5 100%)
				0% 0% no-repeat padding-box`
		},
		spacing: 'lg-spacing',
		headerImage: {
			source: environment.new_web_cdn_url + '/web/assets/img/activations/subscribe-success.png',
			position: 'centered',
			size: 'sm-img'
		},
		backgroundSpecs: {
			color: 'white'
		},
		headerTitle: {
			label: 'congrats_plus',
			size: '2.5em',
			topspacing: '7em',
			trim: true,
			specialTitle: false
		},
		show_benefits: true,
		show_wave: true
	},
	eid: {
		background: {
			default: '#F7ADCE'
		},
		spacing: 'sm-spacing',
		headerImage: {
			source: environment.new_web_cdn_url + '/web/assets/img/star.png',
			position: 'leftcorner',
			size: 'md-img'
		},
		backgroundSpecs: {
			color: '#280AA5'
		},
		headerTitle: {
			label: 'eid_success_title',
			size: '3em',
			topspacing: '2.5em',
			trim: false,
			specialTitle: false
		},
		subtitle: {
			label: 'eid_success_subtitle'
		},
		cta_button: {
			text: 'eid_success_button',
			color: 'dark_blue',
			link: '/home',
			target: 'router'
		},
		show_wave: false,
		promo_section: {
			title: 'eid_success_vouchers_title',
			image: {
				source: environment.new_web_cdn_url + '/web/assets/img/star-white.png',
				position: 'rightcorner',
				size: '40em'
			}
		}
	},
	notice: {
		background:
			'transparent linear-gradient(180deg, #00BDFE 0%, #0031F5 100%) 0% 0% no-repeat padding-box',
		spacing: 'lg-spacing',
		backgroundSpecs: {
			color: 'white'
		},
		show_wave: true
	},
	operator: {
		background: {
			default: `url(\'${environment.new_web_cdn_url}/web/assets/img/landing-operator/operator-bgd-web-updated1.png\')`,
			mobile: `url(\'${environment.new_web_cdn_url}/web/assets/img/landing-operator/operator-bgd-mobile-updated.png\')`
		},
		backgroundSpecs: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			color: '#FFFFFF'
		},
		spacing: 'sm-spacing',
		headerTitle: {
			label: 'eid_success_title',
			topspacing: '2.5em',
			trim: false,
			specialTitle: true
		},
		// subtitle: "eid_success_subtitle",
		// cta_button: {
		//   text: "eid_success_button",
		//   color: "dark_blue",
		//   link: "/home",
		//   target: "router"
		// },
		show_wave: false
	},
	topup: {
		background: {
			default: `url(\'${environment.new_web_cdn_url}/web/assets/img/activations/ding-topup-updated-web-bgd.png\')`,
			mobile: `url(\'${environment.new_web_cdn_url}/web/assets/img/activations/ding-topup-updated-mobile-bgd.png\')`
		},
		backgroundSpecs: {
			backgroundPosition: 'center',
			'background-size': 'cover',
			color: '#FFFFFF'
		},
		backgroundClass: 'bgd-mobile-unset',
		spacing: '',
		headerTitle: {
			label: 'eid_success_title',
			topspacing: '2.5em',
			trim: false,
			specialTitle: true,
			smTitle: true,
			hidebutton: true
		},
		cta_button: {
			color: 'light_pink'
		},
		show_wave: false
	},
	gifts: {
		background: {
			default: '#FFFFFF'
		},
		headerImage: {
			source: environment.web_cdn_url + 'web/assets/img/gift/gift-result.png',
			position: 'centered',
			size: 'xsm-img'
		},
		spacing: '',
		backgroundSpecs: {
			color: '#000000'
		},
		headerTitle: {
			label: 'gifts_delivered_1',
			topspacing: '1em',
			trim: false,
			specialTitle: true,
			smTitle: true,
			hidebutton: true
		},
		subtitle: {
			label: 'gifts_delivered_2',
			classes: 'semi-bold pointer'
		},
		link: {
			url: 'https://anghami.com/redeem/',
			type: 'copylink'
		},
		show_wave: false
	},
	podcasts: {
		background: {
			default: '#563594'
		},
		backgroundSpecs: {
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			color: '#FFFFFF'
		},
		backgroundClass: 'bgd-mobile-unset',
		spacing: '',
		headerTitle: {
			label: 'podcastrss_step4_header_title',
			classes: 'special-title sm-title hide-button'
		},
		subtitle: {
			label: 'podcastrss_step4_header_desc'
		},
		show_wave: false,
		body: {
			mainTitle: {
				label: 'podcastrss_step4_body_title'
			},
			sections: [
				{
					id: 1,
					title: {
						label: 'podcastrss_step4_analytics',
						styles: {
							color: '#563594'
						}
					},
					body: {
						label: 'podcastrss_step4_analytics_desc'
					}
				},
				{
					id: 2,
					title: {
						label: 'podcastrss_step4_manage',
						styles: {
							color: '#563594'
						}
					},
					body: {
						label: 'podcastrss_step4_manage_desc'
					}
				}
			],
			cta: {
				label: 'podcastrss_step4_cta',
				styles: {
					color: '#FEEEB6',
					backgroundColor: '#563594'
				},
				action: {
					type: 'link',
					url: 'https://artists.anghami.com/claim'
				}
			},
			asset: environment.web_cdn_url + 'web/assets/img/podcasts/rss-podcast-success.png'
		}
	}
};

export const staticPagesAssets = {
	corporateoffer: {
		header: {
			background: {
				main: environment.web_cdn_url + 'web/assets/img/corporateoffer/corporate-bgd-header.jpg',
				inner: null
			},
			title: 'corporate_header_title',
			subtitle: 'corporate_anghami_plus_subtitle',
			description: 'corporate_header_description',
			cta: {
				text: 'corporate_cta',
				color: '#5C5CFF'
			},
			isBackdrop: true
		},
		url: {
			en: 'https://forms.gle/jQygDu3NCZ4uG1sWA',
			fr: 'https://forms.gle/jQygDu3NCZ4uG1sWA',
			ar: 'https://docs.google.com/forms/d/e/1FAIpQLSeDR8jD7qT8aRtc7Liuw-yl__oNIxY0-Lv-MFTIYaxDaXusdA/viewform'
		},
		bodyAssets: {
			en: [
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-3months-en.png',
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-6months-en.png',
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-12months-en.png',
				environment.web_cdn_url + 'web/assets/img/corporateoffer/new-logo/family-12months-en.png'
			],
			fr: [
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-3months-en.png',
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-6months-en.png',
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/new-logo/individual-12months-en.png',
				environment.web_cdn_url + 'web/assets/img/corporateoffer/new-logo/family-12months-en.png'
			],
			ar: [
				environment.web_cdn_url + 'web/assets/img/corporateoffer/individual-3months-updated-ar.png',
				environment.web_cdn_url + 'web/assets/img/corporateoffer/individual-6months-updated-ar.png',
				environment.web_cdn_url +
					'web/assets/img/corporateoffer/individual-12months-updated-ar.png',
				environment.web_cdn_url + 'web/assets/img/corporateoffer/family-12months-updated-ar.png'
			]
		}
	},
	affiliate: {
		header: {
			background: {
				main: environment.web_cdn_url + 'web/assets/img/affiliate/affiliate-header-bgd.png',
				inner: environment.web_cdn_url + 'web/assets/img/affiliate/affiliate-header-inner-bgd.png'
			},
			title: null,
			subtitle: 'affiliate_header_title',
			description: null,
			cta: {
				text: 'affiliate_cta',
				color: '#2C205F'
			},
			isBackdrop: false
		},
		url: {
			en: 'https://forms.gle/MFyqtZkR6D2GbXxq7 ',
			fr: 'https://forms.gle/MFyqtZkR6D2GbXxq7 ',
			ar: 'https://forms.gle/fDoB2mGFnPiKYGFaA'
		}
	},
	anghamistudios: {
		header: {
			background: {
				main: environment.web_cdn_url + 'web/assets/img/artist-studio/artiststudio-header-bgd.png',
				inner: null
			},
			extraStyles: {
				header: 'anghami-studios-header',
				title: 'anghami-studios-title',
				description: 'anghami-studios-description'
			},
			extraAsset: {
				src: `${environment.web_cdn_url}web/assets/img/logos/NEW_LOGO_STUDIOS@2x.png`,
				classes: 'anghami-studios-extra-asset'
			},
			title: 'anghami_studios_header_title',
			extraData: {
				type: 'email',
				label: 'anghami_studios_header_subtitle',
				value: 'advertise@anghami.com'
			},
			isBackdrop: false
		}
	}
};

/**
 * @description translation keys for each operator's page
 */
export const operatorsLoginKeys = {
	preorder: {
		title: 'preorder_login',
		cta: 'topup_landing_login'
	},
	concerts: {
		title: 'concert_telco_login_title',
		cta: 'topup_landing_login'
	},
	default: {
		title: 'Login with your Anghami account',
		cta: 'login'
	}
};

export const operatorsPlaceholderKeys = {
	preorder: {
		msidn: 'Enter your phone number',
		code: 'verification_code_placeholder'
	},
	concerts: {
		msidn: 'Enter your phone number',
		code: 'verification_code_placeholder'
	},
	rbt: {
		msidn: 'Enter your phone number',
		code: 'verification_code_placeholder'
	},
	default_etisalatuae: {
		msidn: 'etisalat_placeholder',
		code: 'verification_code_placeholder'
	},
	default: {
		msidn: 'Enter your phone number',
		code: 'verification_code_placeholder'
	}
};

export const successParams = {
	preorder: '1',
	concerts: 'concerts'
};

/**
 * @description list of Anghami sponsored partners
 */
export const sponsoredPartners = [
	{
		type: 'akwad',
		href: 'https://akwad.sa/en/',
		image: environment.web_cdn_url + 'web/assets/img/partners/akwad.png'
	},
	{
		type: 'bob',
		href: 'http://www.bob-finance.com/',
		image: environment.web_cdn_url + 'web/assets/img/partners/BOB.png'
	},
	{
		type: 'cu',
		href: 'http://www.cashunited.com/maps/',
		image: environment.web_cdn_url + 'web/assets/img/partners/cash-united.png'
	},
	{
		type: 'likecard',
		href: 'https://like4card.com/',
		image: environment.web_cdn_url + 'web/assets/img/partners/likecard.png'
	},
	{
		type: 'onecard',
		href: 'https://www.onecard.net/customer/products.html?categoryId=5&localMerchantId=757713',
		image: environment.web_cdn_url + 'web/assets/img/partners/one-card.png'
	},
	{
		type: 'virgin',
		href: 'https://www.virginmegastore.com/index.html',
		image: environment.web_cdn_url + 'web/assets/img/partners/virgin.png'
	},
	{
		type: 'alkhadmah',
		href: 'https://www.alkhdmah.com/en/',
		image: environment.web_cdn_url + 'web/assets/img/partners/alkhamah-updated.png'
	},
	{
		type: 'libanpost',
		href: 'https://www.libanpost.com/',
		image: environment.web_cdn_url + 'web/assets/img/partners/LibanPost_logo.png'
	},
	{
		type: 'whish',
		href: 'https://www.libanpost.com/',
		image: environment.web_cdn_url + 'web/assets/img/partners/whish_logo.png'
	}
];

/**
 * * @description List of users' country codes
 */
export const countryCodes = [
	'LB',
	'DZ',
	'BH',
	'CA',
	'EG',
	'IN',
	'ID',
	'IQ',
	'JO',
	'XK',
	'KW',
	'LY',
	'MW',
	'MY',
	'OM',
	'PK',
	'PS',
	'PH',
	'QA',
	'SA',
	'SD',
	'TN',
	'TR',
	'AE',
	'GB',
	'US',
	'YE'
];

export enum COUNTRY_CODES {
	EGYPT = 'EG',
	LEBANON = 'LB',
	UAE = 'AE'
}

export const WHEEL_PRIZES_ROTATION = {
	3: {
		index_0: 2,
		index_1: 1,
		index_2: 0
	},
	4: {
		index_0: 2,
		index_1: 1,
		index_2: 0,
		index_3: 3
	},
	5: {
		index_0: 3,
		index_1: 2,
		index_2: 1,
		index_3: 5,
		index_4: 4
	}
};

export const ANGHAMI_BENEFITS = [
	{
		title: 'allfeatures_title_1',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/download-1.png',
		alt: 'Download ang Play Offline'
	},
	{
		title: 'allfeatures_title_2',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/noads-1.png',
		alt: 'Ad-free music'
	},
	{
		title: 'allfeatures_title_3',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/audio quality-1.png',
		alt: 'High Quality Music'
	},
	{
		title: 'allfeatures_title_4',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/lyrics-1.png',
		alt: 'Lyrics'
	},
	{
		title: 'allfeatures_title_5',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/import-1.png',
		alt: 'Import Music from Anywhere'
	},
	{
		title: 'allfeatures_title_7',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/repeat-1.png',
		alt: 'Rewind, Scrub & Repeat'
	}
];

export const ANGHAMI_BENEFITS_EXTRA = [
	{
		title: 'anaghami_benefits_shahid_feature_1',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/download-1.png',
		description: 'anaghami_benefits_shahid_feature_desc_1',
		alt: 'Download and Play Offline'
	},
	{
		title: 'anaghami_benefits_shahid_feature_2',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/noads-1.png',
		description: 'anaghami_benefits_shahid_feature_desc_2',
		alt: 'Ad-free music'
	},
	{
		title: 'anaghami_benefits_shahid_feature_3',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/audio quality-1.png',
		description: 'anaghami_benefits_shahid_feature_desc_3',
		alt: 'High Quality Music'
	},
	{
		title: 'anaghami_benefits_shahid_feature_4',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/lyrics-1.png',
		description: 'anaghami_benefits_shahid_feature_desc_4',
		alt: 'Lyrics'
	},
	{
		title: 'anaghami_benefits_shahid_feature_5',
		image: environment.web_cdn_url + 'web/assets/img/new-benefits/repeat-1.png',
		description: 'anaghami_benefits_shahid_feature_desc_5',
		alt: 'Rewind, Scrub & Repeat'
	}
];

export const SHAHID_BENEFITS = [
	{
		image: environment.web_cdn_url + 'web/assets/img/shahid/shahid-benefits/full-hd.png',
		title: 'shahid_feature_1',
		description: 'shahid_feature_desc_1',
		alt: 'Originals & Exclusive premieres'
	},
	{
		image: environment.web_cdn_url + 'web/assets/img/shahid/shahid-benefits/early-preview.png',
		title: 'shahid_feature_2',
		description: 'shahid_feature_desc_2',
		alt: 'Previews before TV'
	},
	{
		image: environment.web_cdn_url + 'web/assets/img/shahid/shahid-benefits/no-ad.png',
		title: 'shahid_feature_3',
		description: 'shahid_feature_desc_3',
		alt: 'No ad-interruptions'
	},
	{
		image: environment.web_cdn_url + 'web/assets/img/shahid/shahid-benefits/full-hd.png',
		title: 'shahid_feature_4',
		description: 'shahid_feature_desc_4',
		alt: 'Unlimited devices'
	},
	{
		image: environment.web_cdn_url + 'web/assets/img/shahid/shahid-benefits/download.png',
		title: 'shahid_feature_5',
		description: 'shahid_feature_desc_5',
		alt: 'Download and watch offline'
	}
];

export const ANGHAMI_GOLD_BENEFITS = [
	{
		title: 'gold_feature_title_7',
		image: environment.web_cdn_url + 'web/assets/img/anghami-gold/updated/benefits/ai_mix.png',
		description: 'gold_feature_subtitle_7',
		alt: 'AI MIX'
	},
	{
		title: 'gold_feature_title_1',
		image:
			environment.web_cdn_url + 'web/assets/img/anghami-gold/updated/benefits/karaoke_updated.png',
		description: 'gold_feature_subtitle_1',
		alt: 'Sing along your favorite songs'
	},
	{
		title: 'gold_feature_title_5',
		image:
			environment.web_cdn_url +
			'web/assets/img/anghami-gold/updated/benefits/badge_app_icon_updated.png',
		description: 'gold_feature_subtitle_5',
		alt: 'Gold Badge'
	},
	{
		title: 'gold_feature_title_4',
		image:
			environment.web_cdn_url +
			'web/assets/img/anghami-gold/updated/benefits/golden_fans_updated.png',
		description: 'gold_feature_subtitle_4',
		alt: 'Golden Fans'
	},
	{
		title: 'gold_feature_title_6',
		image:
			environment.web_cdn_url + 'web/assets/img/anghami-gold/updated/benefits/paip_updated.png',
		description: 'gold_feature_subtitle_6',
		alt: 'Personalized AI Podcast'
	},
	{
		title: 'gold_feature_title_2',
		image:
			environment.web_cdn_url +
			'web/assets/img/anghami-gold/updated/benefits/upload_music_updated.png',
		description: 'gold_feature_subtitle_2',
		alt: 'Own library on the cloud'
	},
	{
		title: 'gold_feature_title_3',
		image:
			environment.web_cdn_url +
			'web/assets/img/anghami-gold/updated/benefits/golden_rewards_updated.png',
		description: 'gold_feature_subtitle_3',
		alt: 'Golden Rewards'
	}
];

export const OLD_ANGHAMI_BENEFITS = [
	{
		title: 'allfeatures_title_1',
		image: environment.images_cdn_url + '/cancel/downloads.png',
		alt: 'Download & Play Offline'
	},
	{
		title: 'allfeatures_title_2',
		image: environment.images_cdn_url + '/cancel/noads.png',
		alt: 'Ad-free Music'
	},
	{
		title: 'allfeatures_title_3',
		image: environment.images_cdn_url + '/cancel/audioquality.png',
		alt: 'High Quality Music'
	},
	{
		title: 'allfeatures_title_4',
		image: environment.images_cdn_url + '/cancel/lyrics.png',
		alt: 'Lyrics'
	},
	{
		title: 'allfeatures_title_7',
		image: environment.images_cdn_url + '/cancel/rewind.png',
		alt: 'Rewind, Scrub & Repeat'
	},
	{
		title: 'plus_features_live_radio',
		image: environment.images_cdn_url + '/cancel/live.png',
		alt: 'Start a Live Radio'
	}
];

export const ANGHAMI_PLUS_BENEFITS = [
	{
		title: 'allfeatures_title_1',
		description: 'plus_features_download_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/downloads.svg',
		alt: 'Download & Play Offline'
	},
	{
		title: 'allfeatures_title_2',
		description: 'plus_features_ad_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/no-ads.svg',
		alt: 'Ad-free Music'
	},
	{
		title: 'allfeatures_title_3',
		description: 'plus_features_hq_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/audio-quality.svg',
		alt: 'High Quality Music'
	},
	{
		title: 'allfeatures_title_4',
		description: 'plus_features_lyrics_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/lyrics.svg',
		alt: 'Lyrics'
	},
	{
		title: 'allfeatures_title_7',
		description: 'plus_features_playercontrols_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/repeat.svg',
		alt: 'Rewind, Scrub & Repeat'
	},
	{
		title: 'plus_features_live_radio',
		description: 'plus_features_live_desc',
		image: environment.new_web_cdn_url + '/web/assets/img/benefits-icons/live-radio.svg',
		alt: 'Start a Live Radio'
	}
];

export const COUNTRY_LIST_LABELS = {
	ext: 'ext.',
	country: 'Phone number country',
	phone: 'Phone',
	AD: 'AND',
	AE: 'UAE',
	AF: 'AFG',
	AG: 'ATG',
	AI: 'AIA',
	AL: 'ALB',
	AM: 'ARM',
	AO: 'AGO',
	AQ: 'ATA',
	AR: 'ARG',
	AS: 'ASM',
	AT: 'AUT',
	AU: 'AUS',
	AW: 'ABW',
	AX: 'ALA',
	AZ: 'AZE',
	BA: 'BIH',
	BB: 'BRB',
	BD: 'BGD',
	BE: 'BEL',
	BF: 'BFA',
	BG: 'BGR',
	BH: 'BHR',
	BI: 'BDI',
	BJ: 'BEN',
	BL: 'BLM',
	BM: 'BMU',
	BN: 'BRN',
	BO: 'BOL',
	BQ: 'BES',
	BR: 'BRA',
	BS: 'BHS',
	BT: 'BTN',
	BV: 'BVT',
	BW: 'BWA',
	BY: 'BLR',
	BZ: 'BLZ',
	CA: 'CAN',
	CC: 'CCK',
	CD: 'COD',
	CF: 'CAF',
	CG: 'COG',
	CH: 'CHE',
	CI: 'CIV',
	CK: 'COK',
	CL: 'CHL',
	CM: 'CMR',
	CN: 'CHN',
	CO: 'COL',
	CR: 'CRI',
	CU: 'CUB',
	CV: 'CPV',
	CW: 'CUW',
	CX: 'CXR',
	CY: 'CYP',
	CZ: 'CZE',
	DE: 'GER',
	DJ: 'DJI',
	DK: 'DNK',
	DM: 'DMA',
	DO: 'DOM',
	DZ: 'DZA',
	EC: 'ECU',
	EE: 'EST',
	EG: 'EGY',
	EH: 'ESH',
	ER: 'ERI',
	ES: 'ESP',
	ET: 'ETH',
	FI: 'FIN',
	FJ: 'FJI',
	FK: 'FLK',
	FM: 'FSM',
	FO: 'FRO',
	FR: 'FRA',
	GA: 'GAB',
	GB: 'GBR',
	GD: 'GRD',
	GE: 'GEO',
	GG: 'GGY',
	GH: 'GHA',
	GI: 'GIB',
	GL: 'GRL',
	GM: 'GMB',
	GN: 'GIN',
	GP: 'GLP',
	GQ: 'GNQ',
	GR: 'GRC',
	GS: 'SGS',
	GT: 'GTM',
	GU: 'GUM',
	GW: 'GNB',
	GY: 'GUY',
	HK: 'HKG',
	HM: 'HMD',
	HN: 'HND',
	HR: 'HRV',
	HT: 'HTI',
	HU: 'HUN',
	ID: 'IDN',
	IE: 'IRL',
	IM: 'IMN',
	IN: 'IND',
	IO: 'IOT',
	IQ: 'IRQ',
	IR: 'IRN',
	IS: 'ISL',
	IT: 'ITA',
	JE: 'JEY',
	JM: 'JAM',
	JO: 'JOR',
	JP: 'JPN',
	KE: 'KEN',
	KG: 'KGZ',
	KH: 'KHM',
	KM: 'COM',
	KN: 'KNA',
	KW: 'KWT',
	KY: 'CYM',
	KZ: 'KAZ',
	LA: 'LAO',
	LB: 'LBN',
	LC: 'LCA',
	LI: 'LIE',
	LK: 'LKA',
	LR: 'LBR',
	LS: 'LSO',
	LT: 'LTU',
	LU: 'LUX',
	LV: 'LVA',
	LY: 'LBY',
	MA: 'MAR',
	MC: 'MCO',
	MD: 'MDA',
	ME: 'MNE',
	MF: 'MAF',
	MG: 'MDG',
	MH: 'MHL',
	MK: 'MKD',
	ML: 'MLI',
	MM: 'MMR',
	MN: 'MNG',
	MO: 'MAC',
	MP: 'MNP',
	MQ: 'MTQ',
	MR: 'MRT',
	MS: 'MSR',
	MT: 'MLT',
	MU: 'MUS',
	MV: 'MDV',
	MW: 'MWI',
	MX: 'MEX',
	MY: 'MYS',
	MZ: 'MOZ',
	NA: 'NAM',
	NC: 'NCL',
	NE: 'MSR',
	NF: 'NFK',
	NG: 'NGA',
	NI: 'NIC',
	NL: 'NLD',
	NO: 'NOR',
	NP: 'NPL',
	NR: 'NRU',
	NU: 'NIU',
	NZ: 'NZL',
	OM: 'OMN',
	PA: 'PAN',
	PE: 'PER',
	PF: 'PYF',
	PG: 'PNG',
	PH: 'PHL',
	PK: 'PAK',
	PL: 'POL',
	PM: 'SPM',
	PN: 'PCN',
	PR: 'PRI',
	PS: 'PLW',
	PT: 'PRT',
	PW: 'PLW',
	PY: 'PRY',
	QA: 'QAT',
	RE: 'REU',
	RO: 'ROU',
	RS: 'SRB',
	RU: 'RUS',
	RW: 'RWA',
	SA: 'SAU',
	SB: 'SLB',
	SC: 'SYC',
	SE: 'SWE',
	SG: 'SGP',
	SH: 'SHN',
	SI: 'SVN',
	SJ: 'SJM',
	SK: 'SVK',
	SL: 'SLE',
	SM: 'SMR',
	SN: 'SEN',
	SO: 'SOM',
	SR: 'SUR',
	SS: 'SSD',
	ST: 'STP',
	SV: 'SLV',
	SX: 'SXM',
	SY: 'SYR',
	TA: 'SHN',
	TC: 'TCA',
	TD: 'TCD',
	TF: 'ATF',
	TG: 'TGO',
	TH: 'THA',
	TJ: 'TJK',
	TK: 'TKL',
	TL: 'TLS',
	TM: 'TKM',
	TN: 'TUN',
	TO: 'TON',
	TR: 'TUR',
	TT: 'TTO',
	TV: 'TUV',
	TW: 'TWN',
	TZ: 'TZA',
	UA: 'UKR',
	UG: 'UGA',
	UM: 'UMI',
	US: 'USA',
	UY: 'URY',
	UZ: 'UZB',
	VA: 'VAT',
	VC: 'VCT',
	VE: 'VEN',
	VG: 'VGB',
	VI: 'VIR',
	VU: 'VUT',
	WF: 'WLF',
	WS: 'WSM',
	XK: 'KSV',
	YE: 'YEM',
	YT: 'MYT',
	ZA: 'ZAF',
	ZM: 'ZMB',
	ZW: 'ZWE',
	ZZ: 'International'
};

export const SUCCESS_UI_STYLES = {
	upgrade: {
		BACKGROUND_COLOR: '#59D07F'
	},
	downgrade: {
		IMG_SIZE: '10rem',
		POSITION: 'column-reverse',
		COLOR: '#000'
	}
};
